import React from "react";
// Customizable Area Start

import LandingPageController, { Props } from "./LandingPageController.web";
export const configJSON = require("./config");
import {
  TravelImage,
} from "./assets";
import { createTheme} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
  Drawer,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import { Close } from "@material-ui/icons";
import AppHeader from "../../../../packages/components/src/AppHeader.web";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#BB393D",
    },
    secondary: {
      main: "#059669",
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
});






// Customizable Area End
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start
  RecruiterBanner = () => {
    return (
     <Banner>
        <BannerUpDiv component={"div"}><img width={"100%"} height={"100%"} src={this.state.bannerData?.Agent && this.state.bannerData.Agent.attributes.url.url} alt="loading..." /></BannerUpDiv>
        <BannerTxtDiv>
          <Box style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"4px"}}>
              <BannerHeader>
                {this.state.bannerData?.Agent?.attributes?.title}
              </BannerHeader>
               <BannerSubHeader> {this.state.bannerData?.Agent?.attributes?.description}
              </BannerSubHeader>
              <BtnBox>
              <LoginBtn onClick={this.navigateToLoginPage}>Login</LoginBtn>
              {this.state.UserRole !== "counsellor" &&
              <SingupBtn onClick={this.navigateToRegistration}>Register</SingupBtn>
            } 
                        </BtnBox>
              </Box>
          </BannerTxtDiv>
      </Banner> 
    );
  };


  renderGetStartedApplyBoard = () => {
    return (
      <Box sx={webStyles.getStartedBoardApplyMainBox} >
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{gap:"41px"}}
          >
            <Grid item xs={12} container style={{gap:"21px"}}>
              <CenterTypography
                >
                {configJSON.everythingInOnePlace}
              </CenterTypography>
              <CenterTypographyTwo
              >
                {configJSON.textlabel11}
              </CenterTypographyTwo>
            </Grid>
            <Grid
                item
                xs={12}
                style={{display:"flex"}}
                justifyContent="center"
                alignItems="center"
              >
               <TabBox>
  {this.state.allServices
    .sort((a, b) => {
      const order = ['Admission Services', 'Finance Services', 'Other Services'];
      return order.indexOf(a.serviceName) - order.indexOf(b.serviceName);
    })
    .map((service, index) => (
      <TabButton
        key={service.id}
        style={{
          backgroundColor: this.state.selectedTab === service.id ? "#059669" : "white",
          color: this.state.selectedTab === service.id ? "white" : "black"
        }}
        data-test-id={`recruiterTab${index}`}
        onClick={() => this.tabSelected(service.id)}
      >
        <TabTypography>{service.serviceName}</TabTypography>
      </TabButton>
    ))}
</TabBox>

              </Grid>
            {Array.isArray(this.state.middleCardData.Agent) && this.state.middleCardData.Agent.length>0 ? this.state.middleCardData.Agent.map((item) => (
              <>
              <Grid item xs={12} md={4} sm={6} lg={3} style={{display:"flex"}} justifyContent="center">
                <ImageCardNew>
                
                <UnderImageBox width="full" height="10.813rem">
                        <img src={item.attributes.url?.url} width="100%" height="100%" />
                        <GradiantColorBox width="100%" height="100%" />
                      </UnderImageBox>
                      <Box style={{display:"flex",height:"50%",flexDirection:"column",justifyContent:"space-between",alignItems:"start"}}>
                  <CardContent>
                    <ImageBoxTextHeader>{item.attributes.title}</ImageBoxTextHeader>
                    <ImageBoxTextDescription>{item.attributes.description}</ImageBoxTextDescription>   
                  </CardContent>
                 
                  </Box>
                </ImageCardNew>
              </Grid>
             </>
            )): (
              <CenterTypographyTwo> There is no Data...! 
              </CenterTypographyTwo>
            )}
          </Grid>
        </Container>
      </Box>
    );
  };



  renderExplorePopularFieldsOfStudyFlag = () => {
    return (
      <Box style={{ padding: "5rem 0"}}>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} justifyContent="center" style={{paddingBottom:"3rem"}}>
              <CenterTypography>
                {configJSON.labelExplorePopularfieldofStudy}
              </CenterTypography>
            </Grid>
            <Grid
                container
                style={{gap:"40px"}}
                justifyContent="center"
                alignItems="center"
              >
            {this.state.flagData.map((flag) => (
              <Grid item xs={12} md={3} sm={6} lg={2} key={flag.id}>
                <Box style={webStyles.justifyCenter}>
                <ImageCardTwo>
                  <UnderImageBox width="230px" height="100px">
                        <img src={flag.logoImg} width="100%" height="100%" />
                        <GradiantColorBox
                         width="100%"
                         height="100%"
                         />
                      </UnderImageBox>
                      <Box style={{height:"full",display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"center",marginTop:'19px'}}>
                    <TextLeftBold
                    style={{fontSize:"1.25rem"}}
                    >
                      {flag.title}
                    </TextLeftBold>
                  </Box>
                </ImageCardTwo>
                </Box>
              </Grid>
            ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };


  renderAchieveYourStudyAboardDreams = () => {
    return (
        <AcheiveGoalsBox>
          <AcheiveGoalsGrid container>
            <Grid item xs={12} md={6} lg={6}>
              <AcheiveGoalsContentBox>
                <Box sx={{ mb: 3 }}>
                  <AcheiveGoalContainerHeader>
                    {configJSON.textLabel}
                  </AcheiveGoalContainerHeader>
                </Box>
                <Box sx={{ mb: 4}}>
                  <AcheiveGoalSubHeader>
                    {configJSON.labelTextSubHeadingSection2}
                  </AcheiveGoalSubHeader>
                </Box>
                <Box>
                  <Button style={{border:"1px solid white",borderRadius:"8px",gap:"8px"}}>
                    <AcheiveGoalButton variant="button"  onClick={()=>this.navigateToAnyPage("Contactus")}>
                      {configJSON.btnLabelGetPersionalizedStudyAbrooadOption}
                    </AcheiveGoalButton>
                  </Button>
                </Box>
              </AcheiveGoalsContentBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={webStyles.justifyCenter}>
              <AcheiveImageBox>
                <img src={TravelImage} width={"100%"} height={"100%"}/>
              </AcheiveImageBox>
            </Grid>
          </AcheiveGoalsGrid>
        </AcheiveGoalsBox>
    );
  };

  renderUnivercityLogo = ()=>{
    return(
      <Box style={{ padding: "5rem 0",
    background: "#F7F7F7"}}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{gap:"80px"}}
        >
          <Grid item xs={12}>
            <UniversityLogoBox>
We Connect You to the Right
School for Your Students            </UniversityLogoBox>
          </Grid>
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{gap:"32px"}}
        >            {this.state.allCountryName.map((country,index)=>
          country.attributes.id === this.state.activeId 
             ? <ActiveButton data-test-id="activeButtonId" key={country.id} style={{textTransform:"uppercase"}} onClick={()=>this.filterBelowCardData(country.attributes.id)}>{country.attributes?.country_name?.toUpperCase()}</ActiveButton>
             : <LoginButton data-test-id={`deActiveButtonId${index}`} key={country.id} style={{textTransform:"uppercase"}} onClick={()=>this.filterBelowCardData(country.attributes.id)}>{country.attributes?.country_name?.toUpperCase()}</LoginButton>
              )}
              </Grid>
          <Grid container
          direction="row"
          justifyContent="center"
          alignContent="center">
          {Array.isArray(this.state.belowCardData.Agent) && this.state.belowCardData.Agent.length>0?this.state.belowCardData.Agent.map((dataCard,index) => (
            (index <= 7 || this.state.showAllUniversitylogo) &&(<Grid item xs={12} sm={6} md={3} style={{...webStyles.justifyCenter,padding:"1rem"}}>
              <LogoImageBox>
              <img src={dataCard.attributes?.url?.url} width={'100%'} height={'100%'}/>
              </LogoImageBox>
            </Grid>)
          )):(
            <CenterTypographyTwo>
There is no Data...! </CenterTypographyTwo>
          )}
        </Grid>
        <Grid item xs={12} style={{display:"flex"}} justifyContent="center">
       {Array.isArray(this.state.belowCardData.Agent) && this.state.belowCardData.Agent.length > 8 &&
       <RegisterButton data-test-id="showUniversitylogoId" onClick={this.showUniversityLogo}>Explore Institutions</RegisterButton>}
        </Grid>
        </Grid>
      </Container>
    </Box>
    )
  }

  defaultSectionLandingPage = () =>{
    return(
      <>         
      {this.RecruiterBanner()}
      {this.renderGetStartedApplyBoard()}
      {this.renderAchieveYourStudyAboardDreams()}
      {this.renderExplorePopularFieldsOfStudyFlag()}
      {this.renderUnivercityLogo()}
        </>
    )
  }


  // Customizable Area End

    render() {
        return (
      // Customizable Area Start
      <>
        <AppHeader isLogin={true}
          navigation={this.props.navigation}
          onLoginClick={this.navigateToLoginPage}
           data-test-id="appHeaderTestId"
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          onHomeClick={this.onHomeClick}
          toggleData={this.state.toggleDrawer}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          showScool={this.state.showSchool}
        />
        {this.defaultSectionLandingPage()}    
         <Footer renderButtonClick={this.renderButtonClick} onAnyPageNavigate={this.navigateToAnyPage}/>      
      </>
      // Customizable Area End
        );
    }
}

// Customizable Area Start

const LoginBtn = styled(Button)({
  background: '#fff',
  border: "2px solid #BB393D",
  height: "40px",
  color: "#BB393D",
  padding: "0px 15px",
  textTransform: "unset",
  fontWeight: 600,
  borderRadius: "8px",
});

const SingupBtn = styled(Button)({
  backgroundColor: "#BB393D",
  fontWeight: 600,
  borderRadius: "8px",
  padding: "0px 15px",
  color: "#fff",
  height: "40px",
  textTransform: "unset",
    "&.MuiButton-root:hover": {
    backgroundColor: "#BB393D"
  }
});

const ImageCardTwo = styled(Card)({
  fontFamily: "Plus Jakarta Sans",
  height: "173px",
  width: "14.375rem",
  boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
});

const ImageCardNew = styled(Card)({
  fontFamily: "Plus Jakarta Sans",
  border: "none",
  height: "24.438rem",
  boxShadow: 'none',
  "& .learn-more-box": {
    border: '1px solid #BB393D',
    padding:'6px 21px',
    borderRadius: '8px',
  },
  "@media (max-width:960px)": {
    width: "17.25rem",
  },
  "@media (min-width:720px)": {
    width: "21.563rem",
  },
  "& .MuiTypography-subtitle2": {
    textTransform: 'capitalize'
  }
});

const UnderImageBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  position: "relative",
  background: "linear-gradient(180deg, rgba(187, 57, 61, 0) 0%, #BB393D 100%)",
});

const GradiantColorBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  bottom: 0,
  position: "absolute",
  left: 0,
})

const AcheiveImageBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    justifyContent: "center",
    display: "flex",
    width: "12.5rem", height: "12.5rem"
  },
  "@media (min-width:720px)": {
    justifyContent: "flex-end",
    display: "flex",
    width: "31.25rem", height: "31.25rem"
  }
});

const AcheiveGoalContainerHeader = styled(Typography)({
  fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "1.125rem",
  },
  "@media (min-width:720px)": {
    fontSize: "2.25rem",
  }
})

const AcheiveGoalSubHeader = styled(Typography)({
  "@media (max-width:960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem",
  }
})

const AcheiveGoalButton = styled(Typography)({
  color: "white", fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  textTransform: 'capitalize',
  "@media (max-width:960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem",
  }
})

const Banner = styled(Box)({
  display: "flex", justifyContent: "center",
  alignItems: "center", minHeight: 645,
  fontFamily: "Plus Jakarta Sans",
  "@media (min-width:960px)": {
    height: 600,
  },
  "@media (min-width:600px)": {
    height: 300,
  },

});

const BannerUpDiv = styled(Box)({
  height: "640px",
  width: "100%",
  position: "absolute",
  opacity: 0.8,
  fontFamily: "Plus Jakarta Sans",
  borderRadius: "8px"
});

const AcheiveGoalsBox = styled(Box)({
  color: "white",
  paddingTop: "5rem",
  width: "100%",
  fontFamily: "Plus Jakarta Sans",
  marginBottom: "2rem"
});

const AcheiveGoalsGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  padding: "2rem",
  fontFamily: "Plus Jakarta Sans",
  background: "#F7F7F7"
});

const AcheiveGoalsContentBox = styled(Box)({
  paddingLeft:'10vw',
  height: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Plus Jakarta Sans",
  justifyContent: "center",
  alignItems: "flex-start",
  color: '#000',
  "& button": {
    background: '#BB393D',
    textTransform: "capitalize",
  }
});

const ImageBoxTextHeader = styled(Typography)({
  fontSize: "24px",
  fontWeight: 'bold',
  textAlign: 'left',
  fontFamily: "Plus Jakarta Sans",
  letterSpacing: 1,
});

const ImageBoxTextDescription = styled(Typography)({
  fontSize: "14px",
  fontWeight: "normal",
  textAlign: "left",
  fontFamily: "Plus Jakarta Sans",
  letterSpacing: 1,
});

const BannerTxtDiv = styled(Box)({
  position: "relative",
  top: 0,
  left: 0,
  fontFamily: "Plus Jakarta Sans",
  width: "100%",
  height: "640px",
  background: "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1C4A39 100%)",
});

const BannerHeader = styled(Typography)({
  color: "white",
  fontWeight: "bold"
  , paddingLeft: "10vw",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "24px", width: "75vw",
    lineHeight: "28px",

  },
  "@media (min-width:720px)": {
    fontSize: "48px", width: "531px",
    lineHeight: "56px",
  }
})
const BannerSubHeader = styled(Typography)({
  color: "white"
  , paddingLeft: "10vw",
  fontFamily: "Plus Jakarta Sans",
  marginBottom: '34px',
  "@media (max-width:960px)": {
    fontSize: "0.8rem", width: "75vw",
    lineHeight: "12px",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem", width: "531px",
    lineHeight: "24px",
  }
})
const CenterTypography = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontSize: "1.875rem",
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "bold",
  "@media (max-width:960px)": {
    fontSize: "1.4rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1.875rem",
  }
});

const CenterTypographyTwo = styled(Typography)({
  width: "100%",
  textAlign: "center",
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "normal",
  lineHeight: "28px",
  margin: "auto",
  "@media (max-width:960px)": {
    fontSize: "1rem", width: "100%"
  },
  "@media (min-width:720px)": {
    fontSize: "1.25rem", width: "645px"
  }
});

const TextLeftBold = styled(Typography)({
  paddingLeft: "0rem",
  marginBottom: "0rem",
  textAlign: "left",
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "bold"
});

const UniversityLogoBox = styled(Typography)({
  textAlign: "left",
  margin: "auto",
  fontSize: "1.875rem",
  color:'#0F172A',
  marginLeft:'13%',
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "bold",
  "@media (max-width:960px)": {
    fontSize: "19px",
    width: "100%",
  },
  "@media (min-width:720px)": {
    fontSize: "1.875rem",
    width: "504px",
  }
})
const LogoImageBox = styled(Box)({
  width: "17.5rem",
  fontFamily: "Plus Jakarta Sans",
  height: "5rem"
});
const BtnBox = styled(Box)(({ }) => ({
  display: "flex",
  gap: "40px",
  paddingLeft: '10vw',
  "@media(max-width: 992px)": {
  }
}));
const LoginButton = styled(Button)({
  borderRadius: "8px",
  border: "2px solid #E2E8F0",
  height: "2.75rem",
  marginRight: "2rem",
  padding: "10px 16px",
  whiteSpace: "nowrap",
  width: "auto",
  color: "#64748B",
  fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  textTransform: "none"
});

const ActiveButton = styled(Button)({
  borderRadius: "8px",
  border: "2px solid",
  height: "2.75rem",
  marginRight: "2rem",
  padding: "10px 16px",
  borderColor: theme.palette.primary.main,
  whiteSpace: "nowrap",
  width: "auto",
  color: theme.palette.primary.main,
  backgroundColor: "transparent",
  fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const TabBox = styled(Box)({
  border: "1px solid #059669",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  borderRadius: "50px",
  fontFamily: "Plus Jakarta Sans",
  width: "fit-content",
  padding: "4px",
  "@media (max-width:960px)": {
    height: "2rem",
    gap: "20px",
  },
  "@media (min-width:820px)": {
    height: "3rem",
    gap: "96px",
  }
});

const TabButton = styled(Button)({
  borderRadius: "50px",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    height: "2rem",
  },
  "@media (min-width:820px)": {
    height: "3rem",
  }
});

const TabTypography = styled(Typography)({
  whiteSpace: "nowrap",
  fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "0.5rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem",
  }
});

const RegisterButton = styled(Button)({
  borderRadius: "8px",
  height: "2.75rem",
  textTransform: "none",
  padding: "10px 16px",
  backgroundColor: theme.palette.primary.main,
  whiteSpace: "nowrap",
  width: "auto",
  color: "white",
  "&:hover": {
    background: "#dcdee0",
    color: "#000",
  },
  fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans"
});

const webStyles = {
  stepIndicatorBox: {
    flex: 1, margin: "30px 0px 0px 0px",
  },
  justifyEnd: {
    justifyContent: "flex-end", display: "flex"
  },
  justifyCenter: {
    justifyContent: "center",
    display: "flex"
  },
  stepOuterCircleBox: {
    justifyContent: "center", alignItems: "center",
    display: "flex", flexDirection: "column",
    margin: "20px 0px 0px 0px",
  },
  fontBold: {
    fontWeight: "bold"
  },

  stepInnerCircleBox: {
    width: "54px", height: "54px",
    border: "1px solid #34D399", borderRadius: "50%",
    backgroundColor: "#34D399", justifyContent: "center",
    alignItems: "center", display: "flex",
  },
  stepTypographyBox: {
    margin: "10px 0px 0px 0px", alignContent: 'center',
    justifyContent: 'center'
  },
  getStartedBoardApplyMainBox: {
    flex: 1, margin: "5rem 0"
  },
  fieldsOfStudiesMainBox: {
    flex: 1,
    margin: "50px 0px 0px 50px",
  },
  widthAndHeight: {
    width: '280px', height: '80px'
  },
  buttonBox: {
    justifyContent: "center", alignItems: "center",
    display: "flex", padding: "2px",
    gap: 50, margin: "50px 0px 0px 50"
  },
  renderHomeHeroBox: {
    background: "white",
    padding: "2rem 0",
  },
  heroSectionBox: {
    fontSize: 38,
    letterSpacing: 1.5,
    fontWeight: "700",
    lineHeight: 1.3,
    marginBottom: "2rem"
  },
  headerDiv: {
    display: "flex",
    backgroundColor: "#F7F7F7"
  }

}
// Customizable Area End