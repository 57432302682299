import React from "react";
// Customizable Area Start

import LandingPageController, { Props } from "./LandingPageController.web";
export const configJSON = require("./config");
import { createTheme } from "@material-ui/core/styles";
import {

  Box,
  Typography,
  styled,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#BB393D",
    },
    secondary: {
      main: "#059669",
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
});






// Customizable Area End
export default class InstitutePage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start
      renderInstitutionsBanner = () => {
    return (
     <AboutUsBanner>
        <BannerUpDiv component={"div"}><img data-test-id="institutebannerImg" className="bannerImg" src={this.state?.institutionsData[0]?.attributes?.banner||""} alt="loading..." /></BannerUpDiv>
        <BannerTxtDiv>
          <Box className="bannerTxtDivInner">
              <BannerHeader>{this.state?.institutionsData[0]?.attributes.banner_title}</BannerHeader>
               <BannerSubHeader dangerouslySetInnerHTML={{__html:this.state?.institutionsData[0]?.attributes?.banner_description||""}}> 
              </BannerSubHeader>
              </Box>
          </BannerTxtDiv>
      </AboutUsBanner> 
    );
  };

  renderInstitutionsCards = () =>{
    const benefits = this.state?.institutionsData[0]?.attributes?.institution_benefits || []
    return <InstitutionsBox>
    <Typography className="headingTxt">{configJSON.instituteTxt}</Typography>
   
    <Box className="institutes">
      {benefits.map((benefit, index) => <Box key={index} className="benefitBox">
        <Box className="sNoBox">
          <span className="sNoTxt">
          {index+1}
          </span>
        </Box>
        <Box className="contentBox">
          <Typography className="instituteHeader">{benefit.title}</Typography>
          <Box className="instituteDesc" dangerouslySetInnerHTML={{__html:benefit.benefits||""}}></Box>
        </Box>

      </Box>)}
    </Box>
    <button className="contactUsBtn" onClick={()=>this.navigateToAnyPage("Contactus")} data-test-id="contactid">{configJSON.contactUsTxt}</button>
    </InstitutionsBox>
    
  }

  renderInstitutionsPage = () =>{
return <>
{this.renderInstitutionsBanner()}
{this.renderInstitutionsCards()}
</>
  }


  // Customizable Area End

    render() {
        return (
      // Customizable Area Start
      <>
        <AppHeader isLogin={true}
        navigation={this.props.navigation}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          showScool={this.state.showSchool}
        />
        {this.renderInstitutionsPage()}
         <Footer renderButtonClick={this.renderButtonClick} onAnyPageNavigate={this.navigateToAnyPage}/>      
      </>
      // Customizable Area End
        );
    }
}

// Customizable Area Start

const BannerUpDiv = styled(Box)({
  height: "640px",
  fontFamily: "Plus Jakarta Sans",
  borderRadius: "8px",
  width: "100%",
  position: "absolute",
  opacity: 0.8,
});

const BannerTxtDiv = styled(Box)({
  position: "relative",
  top: 0,
  width: "100%",
  height: "640px",
  left: 0,
  fontFamily: "Plus Jakarta Sans",
  background: "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1C4A39 100%)",
});

const BannerHeader = styled(Typography)({
  color: "white",
  fontWeight: "bold"
  , paddingLeft: "10vw",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "24px", 
    width: "75vw",
    lineHeight: "28px",

  },
  "@media (min-width:720px)": {
    fontSize: "48px", 
    width: "531px",
    lineHeight: "56px",
  }
})
const BannerSubHeader = styled(Typography)({
  color: "white"
  , paddingLeft: "10vw",
  marginBottom: '34px',
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "0.8rem", 
    lineHeight: "12px",
    width: "75vw",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem",
     width: "531px",
    lineHeight: "24px",
  }
})
const AboutUsBanner = styled(Box)({
  display: "flex",justifyContent: "center",
  fontFamily: "Plus Jakarta Sans",
  alignItems: "center",minHeight: 645,
  "@media (min-width:960px)": {
    height:600,
  },
  "@media (min-width:600px)": {
    height: 300,
  },
  "& .bannerImg": {
     width: "100%",
    height: "100%",
  },
  "& .bannerTxtDivInner": {
    width: "100%", display: "flex",height: "100%", flexDirection: "column", justifyContent: "center", gap: "4px"
  }
});

const InstitutionsBox = styled(Box)({
  "& .headingTxt":{
    marginTop:"70px",
    marginBottom:"50px",
    textAlign:"center",
    fontWeight:700,
    fontSize:"35px",
  },
  "& .institutes":{
    maxWidth:"1000px",
    gap:"20px",
    justifyContent:"center",
    margin:"0 auto",
    display:"flex",
    flexWrap:"wrap",
  },
  "& .benefitBox":{
    maxWidth:"440px",
    display:"flex",
    gap:"20px",
    border:"1px solid #EBEBEB",
    padding:"20px",
    borderRadius:"10px"
  },
  "& .sNoTxt":{
    display:"flex",
    height:"20px",
    justifyContent:"center",
    alignItems:"center",
    background:"#FEE0E0",
    width:"20px",
    padding:"10px",
    color:"#9F4444",
    borderRadius:"50%"
  },
  "& .instituteHeader":{
    fontWeight:"700",
    marginBottom:"10px",
    fontSize:"22px",
  },
  "& .contactUsBtn":{
    display:"block",
    color:"white",
    outline:"none",
    border:"none",
    fontWeight:700,
    padding:"15px",
    borderRadius:"10px",
    fontSize:"16px",
    background:"#33D499",
    margin:"50px auto 70px auto"
  }
})
// Customizable Area End