// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import { Country, State } from "country-state-city";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
import "yup-phone-lite";
import { toast } from "react-toastify";
import { Color } from "@material-ui/lab/Alert";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";
import {
    HourglassEmpty as HourglassEmptyIcon,
    ReportProblem as ReportProblemIcon,
    CheckCircle as CheckCircleIcon
  } from '@mui/icons-material';
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export interface CountryCodeObj {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}

export interface SelectEventType {
    target: {
        value: string | unknown;
    }
}

interface CountryInterfaceData {
    name: string;
    ICountry: [];
    isoCode: string;
    flag: string;
    phonecode: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: {
        zoneName: string;
        gmtOffset: string;
        gmtOffsetName: string;
        abbreviation: string;
        tzName: string;
    };
}
interface FilteredData {
    name: string;
}

export interface RowDataInterface {
    id: number; lastName: string; firstName: string; age: number;
}

interface FileData {
    file: File;
    fileUrl: string;
}
export interface StaffMember {
    id: string;
    type: string;
    attributes: {
        id: number;
        firstname?: string;
        lastname?: string;
        email?: string;
        phone_number?: string;
        status?: string;
        name?: string;
        file_type?: string | null;
        document_category?: string;
        account_id?: number;
        created_at?: string;
        updated_at?: string;
        url?: URL;
    };
};

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
};

interface ValidResponseType {
    personal_id: StaffMember[];
    business_registration_certificate: StaffMember[];
    agent_docs: StaffMember[];
    message: string;
    data: StaffMember[];
    meta: {
        message: string;
    };
    countryCode: string
    agreement:{};
    success:string;
};

export interface BankInformation {
    attributes: {
        id: string;
        bankholder_name: string;
        account_number: string;
        bank_name: string;
        bank_address: string;
        iban: string;
        swiftcode: string;
        primary: boolean;
        created_invoices: null | string;
        paid_invoices: null | string;
    }
};

interface BankInformationResponse {
    data: Array<BankInformation>;
    meta: {
        message: string;
    };
};

export interface UserDetails {
    attributes: {
        allow_navigate: boolean;
        first_name: string;
        company_name: string;
        email: string;
        no_of_employees: string;
        full_phone_number: string;
        country: string;
        state: string;
        city: string;
        verification_type: string;
        activated: string;
        is_logged_in: boolean;
        gender: string;
        date_of_birth: string;
        marital_status: string;
        created_at: string;
        updated_at: string;
        role: string;
        country_code:number;
        phone_number: string,
        marriage_anniversary: string,
        allow_all: boolean,
        preferred_notification: {
            bulkUpdates: boolean,
            conversationMessage: boolean,
            newApplicationRequirement: boolean,
            applicationStage: boolean,
        }
        image: {
            url: string;
        };
        log: {
            data: [
                {
                    id: string;
                    type: string;
                    attributes: {
                        action: string;
                        description: string;
                        ip_address: string;
                        user_id: number;
                        date: string;
                    }
                }
            ]
        }
        percentage: string,
        profile_status : string
    }
};

export interface GetAllDocumentResponse {
    personal_id: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    business_registration_certificate: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    agent_docs: {
        data: [
            {
                id: string,
                type: string,
                attributes: {
                    id: number,
                    name: string,
                    file_type: null,
                    document_category: string,
                    account_id: number,
                    created_at: string,
                    updated_at: string,
                    url: {
                        url: string
                    }
                }
            }
        ]
    },
    meta: {
        message: "List of all documents"
    }
}

interface UserDetailsResponse {
    data: UserDetails;
    meta: {
        message: string;
    };
};

interface InvalidResponseType {
    errors: string;
};

interface InvalidResponseErrorType {
    errors: [
        {
            doc: string;
        }
    ]
};

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface StudentForReassign {
    student_name: string;
    student_id: string;
    passport_number: string
}

interface ProfileImage {
    id: number,
    filename: string,
    url: string,
    type: string
}

interface AgentForReassign {
    id: number;
    agent_name: string;
    agent_id: string;
    profile_image: ProfileImage | null,
    designation:string,
    email:string
  }
  
  interface DataForReassign {
    students: StudentForReassign[];
    agents: AgentForReassign[];
  }
interface ValidResponseType {
    message: string;
    data: StaffMember[];
    meta: {
        message: string;
    }
};

interface DocumentUrl {
    url: string;
}

interface DocumentAttributes {
    id: number;
    name: string;
    file_type: string | null;
    document_category: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    url: DocumentUrl
    attributes: DocumentAttributes
    agent_agreement:any
}

interface Document {
    file: string | Blob;
    id: string;
    type: string;
    attributes?: DocumentAttributes;
    fileUrl: string;
}

export interface ActivityTable {
    "id": string;
    "type": string;
    "attributes": {
        "action": string;
        "description": string;
        "ip_address": string;
        "user_id": number;
        "date": string;
    }
}

interface Category {
    data: Document[];
}


interface Meta {
    message: string;
}
interface FileResponse {
    message: string;
    personal_id: PersonalId;
    business_registration_certificate: BusinessRegistration;
    agent_docs: Category;
    meta: Meta;
}

interface BusinessRegistration {
    data: Document[];
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        file_type: null,
        document_category: string,
        account_id: number,
        created_at: string,
        updated_at: string,
        url: {
            url: string
        }
    }
}
interface PersonalId {
    data: Document[];
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        file_type: null,
        document_category: string,
        account_id: number,
        created_at: string,
        updated_at: string,
        url: {
            url: string
        }
    }
}

interface ManageBranch {
    id: number;
    branch_name: string;
    email: string;
    country_code: string;
    phone_number: number;
    managed_by: string;
    date: string;
    time: string;
    created_at: string;
    updated_at: string;
    country:number
}

interface ManageBranchData {
    id: string;
    type: string;
    attributes: ManageBranch;
}

interface CountryInfo {
    alpha2: string;
    name: string;
    country_code: string;
  }
  
  interface Account {
    id: number;
    email: string;
    role: string;
  }
  interface BankDetailsAttributes {
    id: number;
    bankholder_name: string;
    account_number: string;
    bank_name: string;
    bank_address: string;
    iban: string;
    swiftcode: string;
    primary: boolean;
    currency_preference: string;
  }

  interface BankDetails {
    id: string;
    type: string;
    attributes: BankDetailsAttributes;
  }
  
  interface BankErrorResponse {
    errors: string[];
  }

  interface CurrencyInfo {
    country: string;
    currency: string;
    code: string;
  }
  interface AllAgreementsData {
    data: any
}
interface SendForReviewApiResponse {
    message : string;
    percentage : number;
    profile_status? : string; 
    errors? : {
        message : string;
        percentage : number;
    };
}
interface Student {
    student_name: string;
    student_id: string;
    passport_number: string;
    id: number
}
// Customizable Area End

interface S {
    // Customizable Area Start
    imageUrl: string | null;
    selectedFile: string | null | File;
    selectedFileNew: File | null;
    selectedAgreementFile:File | null;
    uploadedDocumentData:File | null;
    toggleDrawer: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    userDetails: UserDetails;
    isLoader: boolean;
    selected: string;
    value: number;
    isPrimary: boolean;
    UpDatePrimary: boolean;

    memberTxtValue: string;
    memberError: boolean;
    memberErrorTxt: string;

    memberEmailTxtValue: string;
    memberEmailError: boolean;
    memberEmailErrorTxt: string;

    genderError: false,
    genderValue: string | unknown;
    genderErrorTxt: string;

    maritalValue: string | unknown;
    maritalErrorTxt: string;
    maritalError: boolean;

    countryData: Array<CountryInterfaceData>;
    countryValue: string | unknown;
    countryError: boolean;
    countryErrorTxt: string;

    stateData: Array<FilteredData>;
    stateValue: string | unknown;
    stateError: boolean;
    stateErrorTxt: string;

    cityError: boolean;
    cityValue: string;
    cityErrorTxt: string;

    mobileNumberError: boolean;
    mobileNumberValue: string;
    mobileNumberErrorTxt: string;


    isCalender: boolean;
    birthDateValue: string;
    birthDate: Date | null | string;
    birthdayError: boolean;
    birthdayErrorTxt: string;

    isMarriageCalender: boolean;
    marriageDateValue: string;
    marriageDate: Date | null | string;
    marriageError: boolean;
    marriageErrorTxt: string;

    curentPswrd: string;
    currentPswrdError: boolean;
    currentPswrdErrorTxt: string;

    newPswrd: string;
    newPswrdErrorTxt: string;
    newPswrdError: boolean;

    confirmPswrd: string;
    confrimPswrdErrorTxt: string;
    confrimPswrdError: boolean;

    activityTableData: Array<ActivityTable>;

    allowAll: boolean;
    notifications: {
        [key: string]: boolean;
    };

    isDelete: boolean;
    isModal:boolean;

    accountNameValue:string;
    accountNameError:boolean;
    accountNameErrorTxt: string;

    accountNumberValue: string;
    accountNumberError: boolean;
    accountNumberErrorTxt: string;

    
    accountBankNameValue:string;
    accountBankNameError: boolean;
    accountBankNameErrorTxt: string;

    bankAdrsValue:string;
    bankAdrsError: boolean;
    bankAdrsErrorTxt: string;

    ibanValue:string;
    ibanError: boolean;
    ibanErrorTxt: string;

    shiftValue:string;
    shiftError: boolean;
    shiftErrorTxt: string;
    branchModal: boolean;
    branchName: string;
    branchEmailID: string;
    branchCountryCode: string;
    branchMobileNumber: string;
    branchAgent: string;
    allBranchData:ManageBranchData[],
    allDocumentsData:any,
    allAgreementsData: any,
    countryCodes: CountryInfo[],
    branchNameError: string;
    branchEmailError: string;
    branchMobileNumberError: string;
    branchAgentError: string;
    isBranchEdit: boolean,
    branchEditID:number,
    allBankDetails: BankDetails[],
    bankEditID: number,
    bankEditName: string,
    isBankEdit: boolean,
    bankErrorResponse: BankErrorResponse[],
    currencyCode:CurrencyInfo[],
    selectedCurrency:string,
    currencyError: boolean,
    currencyErrorTxt: string,
    viewedDocuments: any[],
    expandedRowId: number | null;
    isBranchDelete:boolean;
    staffRowData: {
        id: number;
        type: string;
        attributes: {
            first_name: string;
            last_name: string;
            email: string;
            phone_number: number;
            country_code: number;
            role: string;
            status: string;
            designation: string;
            student_count: number;
            application_count: number
        };
    }[];
    FilterStaffRowData: {
        id: number;
        type: string;
        attributes: {
            first_name: string;
            last_name: string;
            email: string;
            phone_number: number;
            country_code: number;
            role: string;
            status: string;
            designation: string;
            student_count: number;
            application_count: number
        };
    }[]
    MangeStaffModel: boolean,
    MangeStaffFormData: {
        name: string,
        lastName: string,
        email: string,
        mobile: string,
        role: string,
        designation: string
    },
    isMangeStaffEdit: boolean,
    StaffId:string,
    UserRole: string,
    AccontCountryCode: string,
    profileCompletionPercentage : string,
    isEligibleForReview : boolean,
    profileStatus : string,
    studentData: Student[],
    openReassign: boolean,
    associateValue: string | unknown,
    currentPage: number,
    itemsPerPage: number,
    reassignData: DataForReassign;
    reassignAgentError:string;
    reassignStudentError: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    
    postProfilePictureAPIcallId = "";
    getProfileAPICallId: string = "";
    changePswrdAPICallId: string = "";
    fetchAllBranchAPICallID: string = ""
    fetchAllDocumentsID: string = "";
    fetchAllAgreementsID: string = "";
    uploadAgreementsID: string ="";
    uploadDocumentID :string= "";
    deleteDocumentID:string= "";
    deleteAgreementID:string= "";
    getCountryCodeAPICallID: string = ""
    fetchManagedByAPICallID: string = ""
    saveBranchAPICallID: string = ""
    validateMobileNoAPICallID: string = ""
    fetchAllBanksAPICallID: string = ""
    saveBankInfoAPICallID: string = ""
    saveNotificationPreference: string = ""
    saveProfileAccountAPICallID: string = "";
    GetStaffApiCallId:string = "";
    AddNewStaffApiCall: string = "";
    sendForReviewApiCallId : string = ""
    getAllListStudentAPICallId: string = ""
    reassignAllAPICallId: string = ""
    activateDeactivateAPICallId: string = ""

        // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];
        this.state = {
            uploadedDocumentData:null,
            imageUrl: "",
            selectedFile: "",
            selectedFileNew:null,
            alertType: "success",
            userDetails: {} as UserDetails,
            isLoader: false,
            selected: "Account",
            value: 0,
            selectedAgreementFile:null,
            toggleDrawer: false,
            isAlert: false,
            alertMsg: "",
            isPrimary: false,
            UpDatePrimary:false,

            memberTxtValue: "",
            memberError: false,
            memberErrorTxt: "",

            memberEmailTxtValue: "",
            memberEmailError: false,
            memberEmailErrorTxt: "",

            genderError: false,
            genderValue: "none",
            genderErrorTxt: "",

            maritalValue: "none",
            maritalErrorTxt: "",
            maritalError: false,

            countryData: Country.getAllCountries() as never,
            countryValue: "none",
            countryError: false,
            countryErrorTxt: "",

            stateData: [],
            stateValue: "",
            stateError: false,
            stateErrorTxt: "",

            cityError: false,
            cityValue: "",
            cityErrorTxt: "",

            mobileNumberError: false,
            mobileNumberValue: "",
            mobileNumberErrorTxt: "",

            isCalender: false,
            birthDateValue: "Select Birthday",
            birthDate: new Date(),
            birthdayError: false,
            birthdayErrorTxt: "",

            isMarriageCalender: false,
            marriageDateValue: "Select marriage anniversary",
            marriageDate: new Date(),
            marriageError: false,
            marriageErrorTxt: "",

            curentPswrd: "",
            currentPswrdError: false,
            currentPswrdErrorTxt: "",

            newPswrd: "",
            newPswrdErrorTxt: "",
            newPswrdError: false,

            confirmPswrd: "",
            confrimPswrdErrorTxt: "",
            confrimPswrdError: false,

            activityTableData: [],

            allowAll: false,
            notifications: {
                applicationStage: false,
                bulkUpdates: false,
                conversationMessage: false,
                newApplicationRequirement: false,
            },

            isDelete:false,
            isModal:false,
            
            accountNameValue:"",
    accountNameError:false,
    accountNameErrorTxt: "",

    accountNumberValue: "",
    accountNumberError: false,
    accountNumberErrorTxt: "",

    
    accountBankNameValue:"",
    accountBankNameError: false,
    accountBankNameErrorTxt: "",

    bankAdrsValue:"",
    bankAdrsError: false,
    bankAdrsErrorTxt: "",

    ibanValue:"",
    ibanError: false,
    ibanErrorTxt: "",

    shiftValue:"",
    shiftError: false,
    shiftErrorTxt: "",
    branchModal: false,
    branchName:"",
    branchEmailID:"",
    branchCountryCode:"91-IN",
    branchMobileNumber:"",
    branchAgent:"",
    allBranchData:[],
    allDocumentsData:[],
    allAgreementsData: [],
    countryCodes:[],
    branchNameError:"",
    branchEmailError:"",
    branchMobileNumberError:"",
    branchAgentError:"",
    isBranchEdit: false,
    branchEditID:0,
    allBankDetails:[],
    bankEditID:0,
    bankEditName:"",
    isBankEdit:false,
    bankErrorResponse:[],
    isBranchDelete: false,
    MangeStaffModel: false,
    currencyCode:[
        { country: "Australia", currency: "Australian Dollar", code: "AUD" },
        { country: "Brazil", currency: "Brazilian Real", code: "BRL" },
        { country: "Canada", currency: "Canadian Dollar", code: "CAD" },
        { country: "China", currency: "Chinese Yuan", code: "CNY" },
        { country: "Denmark", currency: "Danish Krone", code: "DKK" },
        { country: "Eurozone", currency: "Euro", code: "EUR" },
        { country: "India", currency: "Indian Rupee", code: "INR" },
        { country: "Japan", currency: "Japanese Yen", code: "JPY" },
        { country: "Mexico", currency: "Mexican Peso", code: "MXN" },
        { country: "New Zealand", currency: "New Zealand Dollar", code: "NZD" },
        { country: "Norway", currency: "Norwegian Krone", code: "NOK" },
        { country: "Russia", currency: "Russian Ruble", code: "RUB" },
        { country: "Singapore", currency: "Singapore Dollar", code: "SGD" },
        { country: "South Africa", currency: "South African Rand", code: "ZAR" },
        { country: "South Korea", currency: "South Korean Won", code: "KRW" },
        { country: "Sweden", currency: "Swedish Krona", code: "SEK" },
        { country: "Switzerland", currency: "Swiss Franc", code: "CHF" },
        { country: "United Kingdom", currency: "British Pound", code: "GBP" },
        { country: "United States", currency: "US Dollar", code: "USD" }
      ],
      selectedCurrency:"none",
      currencyError: false,
      currencyErrorTxt: "",
      viewedDocuments: [],
      expandedRowId: null,
      staffRowData:[],
      FilterStaffRowData:[],
            MangeStaffFormData: {
                name: '',
                lastName: '',
                email: '',
                mobile: '',
                role: "",
                designation: ''
            },
            isMangeStaffEdit: false,
            StaffId: '',
            UserRole: '',
            AccontCountryCode: "91-IN",
            profileCompletionPercentage : "",
            isEligibleForReview : false,
            profileStatus : "",
            studentData:[],
            openReassign: false,
            associateValue: "none",
            currentPage: 0,
            itemsPerPage: 10,
            reassignData:{
                students: [], 
                agents: []
              },
            reassignAgentError:"",
            reassignStudentError:""
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        await this.getProfileAccount();
        await this.getCountryCode();
        await this.fetchAllBranches()
        await this.fetchAllDocuments();
        await this.fetchAllBankDetails();
        await this.fetchAllAgreements();
        await this.getAllStaffData()
         const viewedDocuments = localStorage.getItem('viewedDocuments');
    if (viewedDocuments) {
      this.setState({ viewedDocuments: JSON.parse(viewedDocuments) });
    }
    let token = await getStorageData("token")
    if (token === null) {
        this.onHomeClick("LandingPage")
    }
    const UserRole = await getStorageData("role")
    this.setState({
      UserRole: UserRole
    })
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    selectedFile: file,
                    imageUrl: reader.result as string
                }, () => {
                    this.postProfilePicture(file)
                });
            };
            reader.readAsDataURL(file);
        }
    };

    handleDocumentViewed = (docId: string, agentId: any) => {
        console.log(docId, agentId, 'viewsss');
      
        // Use a fallback empty object if localStorage.getItem returns null
        const storedViewedDocuments = JSON.parse(localStorage.getItem('viewedDocuments') || '{}');
        const agentViewedDocs = storedViewedDocuments[agentId] || [];
      
        if (!agentViewedDocs.includes(docId)) {
          const updatedViewedDocuments = [...agentViewedDocs, docId];
          storedViewedDocuments[agentId] = updatedViewedDocuments;
          
          // Safely store the updated viewed documents back into localStorage
          localStorage.setItem('viewedDocuments', JSON.stringify(storedViewedDocuments));
      
          // Update the state with the new document information
          this.setState((prevState: any) => ({
            viewedDocuments: {
              ...prevState.viewedDocuments,
              [agentId]: updatedViewedDocuments,
            }
          }));
        }
      };
    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage),"LandingPage");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({ 
            toggleDrawer: !this.state.toggleDrawer 
        });
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggleAbove = (option: string) => {
        this.setState({ selected: option });
    };

    apiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiData;

        let token = await getStorageData("token")

        const header = {
            "Content-Type": contentType,
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return requestMessage.messageId;
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data) || responseJson.message || responseJson.agent_docs || responseJson.business_registration_certificate || responseJson.personal_id || responseJson.meta || Array.isArray(responseJson) || responseJson.agreement || responseJson.success
    };

    apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType & UserDetailsResponse & FileResponse) => {
        if (apiRequestCallId === this.getProfileAPICallId) {
            this.getProfileSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.changePswrdAPICallId) {
            this.changePswrdeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postProfilePictureAPIcallId) {
            this.profilePictureSuccessCall(responseJson);
        }
        if (apiRequestCallId === this.fetchAllBranchAPICallID) {
            this.fetchAllBranchSuccessCall(responseJson)
        }
        
        if (apiRequestCallId === this.fetchAllDocumentsID) {
            this.fetchAllDocumentsSuccessCall(responseJson)
        }
        if (apiRequestCallId === this.fetchAllAgreementsID) {
            this.fetchAllAgreementsSuccessCall(responseJson)
        }
        if (apiRequestCallId === this.uploadAgreementsID) {
            this.uploadAgreementsSuccessCall(responseJson)
        }
        if (apiRequestCallId === this.deleteAgreementID) { 
            this.deleteAgreementSuccessCall(); 
        }
        if (apiRequestCallId === this.uploadDocumentID) { 
            this.uploadDocumentSuccessCall(responseJson); 
        } 
        if (apiRequestCallId === this.deleteDocumentID) { 
            this.deleteDocumentSuccessCall(); 
        }
        if (apiRequestCallId === this.getCountryCodeAPICallID) {
            this.setCountryCodes(responseJson)
        }
        if (apiRequestCallId === this.saveBranchAPICallID) {
            this.resetBranchFormData()
        }
        if (apiRequestCallId === this.fetchAllBanksAPICallID) {
            this.fetchAllBankSuccessCall(responseJson)
        }
        if (apiRequestCallId === this.saveBankInfoAPICallID) {
            this.setBankMessages(responseJson)
        }
        if(apiRequestCallId === this.saveProfileAccountAPICallID) {
            this.handleUpdateProfile(responseJson)
        }
    };

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponseErrorType) => {
        if (apiRequestCallId === this.postProfilePictureAPIcallId) {
            this.profilePictureFailureCall(responseJson);
        }
        if (apiRequestCallId === this.changePswrdAPICallId) {
            this.changePswrdFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.validateMobileNoAPICallID) {
            this.setContactErrors(responseJson)
        }
        if (apiRequestCallId === this.saveBankInfoAPICallID) {
            this.setBankResponseError(responseJson)
        }
        if (apiRequestCallId === this.saveBranchAPICallID) {
            this.setBranchResponseError(responseJson)
        }
    };

    changePswrdeSucessCallBack = (responseJson: ValidResponseType) => {
        this.setState({
            curentPswrd: "", isLoader: false, isAlert: true, alertMsg: responseJson.message, alertType: "success", newPswrd: "", confirmPswrd: ""
        });
    };

    changePswrdFailureCallBack = (responseJson: InvalidResponseType) => {
        this.setState({
            isAlert: true, alertMsg: responseJson.errors, alertType: "error", isLoader: false
        });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId, responseJson);
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson);
            }
            if(apiRequestCallId === this.GetStaffApiCallId){
                this.SetStaffData(responseJson)
            }
            if(apiRequestCallId === this.AddNewStaffApiCall){
                this.AddStaffAPIResponse(responseJson)
            }
            if (apiRequestCallId === this.sendForReviewApiCallId) {
                this.handleSuccessSendForReview(responseJson)
            }

            const callbacks = {
                [this.getAllListStudentAPICallId]: (responseJson: any) => {
                    this.handleAllListofStudentAndCouns(responseJson)
                },
                [this.reassignAllAPICallId]: (responseJson: any) => {
                    this.handleReassignStudent(responseJson)
                },
                [this.activateDeactivateAPICallId]:(responseJson: any) => {
                    this.handleActivateDeactivate(responseJson)
                },
              };
              
              const callback = callbacks[apiRequestCallId];
              if (callback) {
                callback(responseJson);
              }
        }
    };


    AddStaffAPIResponse = (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
        if (responseJson && !responseJson.errors) {
            this.getAllStaffData()
            if (this.state.isMangeStaffEdit) {
                toast.success("Update Successfully")
            } else {
                toast.success("Add Successfully")
            }
            this.setState({
                isLoader: false,
                MangeStaffFormData: {
                    name: '',
                    lastName: '',
                    email: '',
                    mobile: '',
                    role: "",
                    designation: ''
                },
                branchMobileNumberError: '',
                StaffId: '',
                isMangeStaffEdit: false
            })
        }else{
            this.ErrorToastHander(responseJson)
            this.setState({
                isLoader: false,
                MangeStaffModel: true
            })
        }
    }

    ErrorToastHander = (responseJson: { [key: string]: Array<{ [key: string]: string }> }) => {
        if (Array.isArray(responseJson.errors)) {
            toast.error(Object.values(responseJson.errors[0])[0] )
        } else {
            toast.error(Object.values(responseJson.errors)[0] as string )
        }
    }

    SetStaffData = (ApiResonse: {data: Array<{
        id: number;
        type: string;
        attributes: { first_name: string; last_name: string; email: string; phone_number: number; country_code: number; role: string; status: string; designation: string; student_count: number;
            application_count: number };
    }>
    }) => {
        this.setState({
            staffRowData:ApiResonse.data.slice(this.state.currentPage, this.state.itemsPerPage),
            FilterStaffRowData: ApiResonse.data,
            isLoader: false
        })
    }   

    getProfileAccount = async () => {
        this.setState({ isLoader: true });
        let userDetails = await getStorageData("userDetails")
        this.getProfileAPICallId = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.httpGetMethod,
            endPoint: configJSON.getProfileEndPoint + userDetails
        });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    getProfileSuccessCallBack = (responseJson: UserDetailsResponse) => {
        const responseData = responseJson.data.attributes
        this.setState({
            userDetails: responseJson.data,
            memberEmailTxtValue: responseData.email,
            genderValue: responseData.gender === null ? configJSON.genderValue : responseData?.gender,
            birthDateValue: responseData.date_of_birth === null ? configJSON.birthdayValue : responseData?.date_of_birth,
            maritalValue: responseData.marital_status === null ? configJSON.genderValue : responseData?.marital_status,
            stateValue: responseData.state ?? 'none',
            cityValue: responseData.city ?? '',
            memberTxtValue:responseData.first_name,
            isLoader: false,
            activityTableData: responseJson?.data?.attributes.log.data,
            mobileNumberValue: responseData.phone_number,
            AccontCountryCode: `${responseData?.country_code?.toString()}-${responseData?.country}`,
            marriageDateValue: responseData.marriage_anniversary === null ? "Select marriage anniversary" : responseData.marriage_anniversary,
            allowAll:responseData.allow_all,
            notifications: {
                bulkUpdates: responseData?.preferred_notification?.bulkUpdates,
                conversationMessage: responseData?.preferred_notification?.conversationMessage,
                newApplicationRequirement: responseData?.preferred_notification?.newApplicationRequirement,
                applicationStage: responseData?.preferred_notification?.applicationStage
            },
            profileCompletionPercentage : responseData?.percentage,
            profileStatus:responseData?.profile_status
        }, () => {
            this.setState({
                countryValue: responseData.country
            })
            console.log(responseData.country,'countrrr')
            const statedata = State.getAllStates()
            const filteredData = statedata.filter((data: { countryCode: string; }) =>
                data.countryCode === responseData.country
            );
            if (filteredData.length === 0) {
                this.setState({
                    stateData: [{
                        name: "N/A"
                    }],
                })
            }
            else {
                const ContryValue = this.state.countryCodes.filter((item) => {
                    return item.alpha2 === this.state.countryValue
                })
                this.setState({
                    stateData: filteredData,
                })
            }
            // this.handleCountryChange({ target: { value: responseData.country } })
        }
        );
    };

    postProfilePicture = async (file: File) => {
        this.setState({ isLoader: true });
        const formdata = new FormData();
        formdata.append("image", this.state.selectedFile as File);
        this.postProfilePictureAPIcallId = await this.apiCall({
            method: configJSON.httpPostMethod,
            endPoint: configJSON.postProfileEndPoint,
            type: configJSON.bankdetailsType,
            body: formdata
        });
    };

    profilePictureSuccessCall = (responseJson: FileResponse) => {
        this.setState(
            {
                alertMsg: responseJson.meta.message,
                isAlert: true,
                alertType: "success",
                isLoader: false
            },
            () => { 
                this.getProfileAccount() 
            }
        )
    };

    profilePictureFailureCall = (responseJson: InvalidResponseType) => {
        this.setState({
            isAlert: true,
            alertType: "error",
            alertMsg: responseJson.errors,
            isLoader: false
        });
    };

    memberTxtChange = (event: { target: { value: string; }; }) => {
    const regex = configJSON.regaxText;
    const inputValue = event.target.value;

    // Validate the input value
    if (inputValue.trim() === "") {
        this.setState({ 
            memberErrorTxt: "Member name is required." 
        });
    } else if (!regex.test(inputValue)) {
        this.setState({ 
            memberErrorTxt: "Invalid member name format."  // Set error message if input is invalid based on regex
        });
    } else {
        this.setState({ 
            memberErrorTxt: ""  // Clear error message if input is valid
        });
    }

    // Update the member name state
    this.setState({ 
        memberTxtValue: inputValue  // Update the state with the input value
    });
};


    memberEmailChange = (event: { target: { value: string; }; }) => {

        const inputValue = event.target.value;

    // Validate the input value
    if (inputValue.trim() === "") {
        this.setState({ 
            memberEmailErrorTxt: "Member email is required."  // Set error message if input is empty
        });
    } else if (!configJSON.emailRegex.test(inputValue)) {
        this.setState({ 
            memberEmailErrorTxt: "Invalid email format."  // Set error message if input is invalid based on regex
        });
    } else {
        this.setState({ 
            memberEmailErrorTxt: ""  // Clear error message if input is valid
        });
    }


        this.setState({ memberEmailTxtValue: event.target.value });
    };

    genderChange = (event: SelectEventType) => {
        const selectedGender = event.target.value;
    
        this.setState({
            genderValue: selectedGender,
            genderErrorTxt: selectedGender !== "none" ? "" : this.state.genderErrorTxt // Clear the error if a valid gender is selected
        });
    };

    maritalChange = (event: SelectEventType) => {
        const selectedMaritalStatus = event.target.value;
    
        this.setState({
            maritalValue: selectedMaritalStatus,
            maritalErrorTxt: selectedMaritalStatus !== "none" ? "" : this.state.maritalErrorTxt // Clear the error if a valid marital status is selected
        });
    };


    handleCountryChange = (event: SelectEventType) => {
        const statedata = State.getAllStates()
        if(event.target.value){
            this.setState({ countryValue: event.target.value }, () => {
                const filteredData = statedata.filter((data: { countryCode: string; }) =>
                    data.countryCode === this.state.countryValue
                );
                if (filteredData.length === 0) {
                    this.setState({
                        stateData: [{
                            name: "N/A"
                        }],
                    stateValue: "none"
                    })
                }
                else {
                    const ContryValue = this.state.countryCodes.filter((item)=>{
                        return item.alpha2 === this.state.countryValue
                      })
                    this.validateMobile(this.state.mobileNumberValue,`${ContryValue[0]?.country_code}-${ContryValue[0]?.alpha2}`)
                    this.setState({
                    stateData: filteredData,
                    stateValue: "none"
                })
            }
            }
            )
        }
    };

    handleStateChange = (event: SelectEventType) => {
        this.setState({ stateValue: event.target.value,
            stateErrorTxt:"",
            stateError:false,
        });
    };

    memberCityChange = (event: { target: { value: string; }; }) => {
        const regex = configJSON.regaxText
        const inputValue = event.target.value;

        if (inputValue.trim() === "") {
            this.setState({ 
                cityErrorTxt: "Member city is required."  // Set error message if input is empty
            });
        } else if (!regex.test(inputValue)) {
            this.setState({ 
                cityErrorTxt: "Invalid city format."  // Set error message if input is invalid based on regex
            });
        } else {
            this.setState({ 
                cityErrorTxt: ""  // Clear error message if input is valid
            });
        }


        if (regex.test(inputValue) || inputValue === "") {
            this.setState({ cityValue: inputValue });
        }
    };

    mobileChange = (event: { target: { value: string; }; }) => {
        const regex = configJSON.mobileReagx
        const inputValue = event.target.value.replace(/\D/g, "").trim();
        

        if (inputValue.trim() === "") {
            this.setState({ 
                mobileNumberErrorTxt: "Phone number is required."  // Set error message if input is empty
            });
        }  else {
            this.setState({ 
                mobileNumberErrorTxt: ""  // Clear error message if input is valid
            });
        }

        const ContryValue = this.state.countryCodes.filter((item)=>{
            return item.alpha2 === this.state.countryValue
          })
        // this.validateMobile(this.state.mobileNumberValue,`${ContryValue[0]?.country_code}-${ContryValue[0]?.alpha2}`)


       
            this.setState({ mobileNumberValue: inputValue, branchMobileNumberError:"" },()=>(
                this.validateMobile(inputValue,`${ContryValue[0]?.country_code}-${ContryValue[0]?.alpha2}`)
            ));
        
    };
    onCalenderClick = () => {
        if(this.state.profileStatus === "fully_approved") return
        this.setState({ isCalender: !this.state.isCalender });
    };

    calculateAge = (birthDate: Date): number => {
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = currentDate.getMonth() - birthDate.getMonth();
        const dayDiff = currentDate.getDate() - birthDate.getDate();
    
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
    
        return age;
    };

    handleDateChange = (date: Date | null) => {
        if (!date) {
            this.setState({
                birthDate: null,
                isCalender: !this.state.isCalender,
                birthDateValue: "Select Birthday",
                birthdayErrorTxt: "Please enter a Date",
                birthdayError: true
            });
            return;
        }

        const age = this.calculateAge(date);
        if (age < 18) {
            this.setState({
                birthDate: null,
                isCalender: !this.state.isCalender,
                birthDateValue: "Select Birthday",
                birthdayErrorTxt: "You must be at least 18 years old to use this portal.",
                birthdayError: true
            });
            return;
        }

        const dayValue = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const formattedDate = `${dayValue}/${month}/${year}`;

        this.setState({
            birthDate: date,
            isCalender: !this.state.isCalender,
            birthDateValue: formattedDate,
            birthdayErrorTxt: "",
            birthdayError: false
        });
    };
    
    

    onMarrigeCalendarClick = () => {
        this.setState({ isMarriageCalender: !this.state.isMarriageCalender });
    };
    handleMarrigeDateChange = (date: Date | null) => {
        if (date) {
            const dayValue = date.getDate().toString().padStart(2, '0'); // Ensures two digits for day
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensures two digits for month
            const year = date.getFullYear().toString().slice(-2); // Extracts the last two digits of the year
            const formattedDate = `${dayValue}/${month}/${year}`; // Format as DD/MM/YY
    
            this.setState({
                marriageDate: date,
                isMarriageCalender: !this.state.isMarriageCalender,
                marriageDateValue: formattedDate,
                marriageErrorTxt: "" 
            });
        } else {
            this.setState({
                marriageDate: null,
                isMarriageCalender: !this.state.isMarriageCalender,
                marriageDateValue: "",
                marriageErrorTxt: "Please enter Date" 
            });
        }
    };
    
    
    


    handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const emailRegex = configJSON.emailRegex
        const validationChecks = [
            { value: this.state.memberTxtValue === "" || this.state.memberTxtValue === null, errorState: "memberError", errorTxtState: "memberErrorTxt", errorMessage: "Please enter the member's name." },
            { value: this.state.genderValue === "none", errorState: "genderError", errorTxtState: "genderErrorTxt", errorMessage: "Please select the member's gender." },
            { value: this.state.maritalValue === "none", errorState: "maritalError", errorTxtState: "maritalErrorTxt", errorMessage: "Please select the member's marital status." },
            { value: this.state.countryValue === "none", errorState: "countryError", errorTxtState: "countryErrorTxt", errorMessage: "Please select the member's country." },
            { value: this.state.stateValue === "none", errorState: "stateError", errorTxtState: "stateErrorTxt", errorMessage: "Please select the member's state." },
            { value: this.state.mobileNumberValue === "" || this.state.mobileNumberValue === null, errorState: "mobileNumberError", errorTxtState: "mobileNumberErrorTxt", errorMessage: "Please enter the member's mobile number." },
            { value: this.state.cityValue === "", errorState: "cityError", errorTxtState: "cityErrorTxt", errorMessage: "Please enter the member's city." },
            { value: this.state.birthDateValue === "Select Birthday", errorState: "birthdayError", errorTxtState: "birthdayErrorTxt", errorMessage: "Please select the member's birthday." },
            {
                value: this.state.memberEmailTxtValue === "" || !emailRegex.test(this.state.memberEmailTxtValue),
                errorState: "memberEmailError",
                errorTxtState: "memberEmailErrorTxt",
                errorMessage: this.state.memberEmailTxtValue === "" ? "Please enter the member's email address." : "Please enter a valid email address."
            }
        ];

        this.setState(prevState => {
            const updatedState: { [key: string]: boolean | string } = {};
            validationChecks.forEach(({ value, errorState, errorTxtState, errorMessage }) => {
                updatedState[errorState] = value;
                updatedState[errorTxtState] = value ? errorMessage : "";
            });
            return { ...prevState, ...updatedState };
        })

        let trueCount = 0;

        for (const item of validationChecks) {
            if (item.value) {
                trueCount++;
                break;
            }
        }

        if (trueCount === 0) {
            if (this.state.branchMobileNumberError=== "" && this.state.mobileNumberErrorTxt==="") {
                this.saveProfileAccount();
            }
        }
    };

    curentPswrdChange = (event: {
        target: {
            value: string;
        };
    }) => {
        const inputValue = event.target.value;

        if (inputValue.trim() === "") {
            this.setState({ currentPswrdErrorTxt: "Current Password is required." });
        } else {
            this.setState({ currentPswrdErrorTxt: "" });
        }

        this.setState({ curentPswrd: event.target.value });
    };

    newPswrdChange = (event: { target: { value: string; }; }) => {

        const inputValue = event.target.value;

        if (inputValue.trim() === "") {
            this.setState({ newPswrdErrorTxt: "New Password is required." });
        } else {
            this.setState({  newPswrdErrorTxt: "" });
        }
        this.setState({  newPswrd: event.target.value });
    };

    confirmPswrdChange = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;

        if (inputValue.trim() === "") {
            this.setState({ confrimPswrdErrorTxt: "Confirm password is required." });
        } else {
            this.setState({ confrimPswrdErrorTxt: "" });
        }
        this.setState({
            confirmPswrd: event.target.value
        });
    };

    onPswrdSave = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        let isCheck = true;
        const passwordRegex = configJSON.passwordRegex
        if (this.state.curentPswrd.length === 0) {
            isCheck = false;
            this.setState({ currentPswrdError: true, currentPswrdErrorTxt: configJSON.currentPswrdReq });
        } else if (!passwordRegex.test(this.state.curentPswrd)) {
            isCheck = false;
            this.setState({ currentPswrdError: true, currentPswrdErrorTxt: configJSON.currentPswrdValid });
        }
        else {
            this.setState({ currentPswrdError: false, currentPswrdErrorTxt: "" });
        }

        if (this.state.newPswrd.length === 0) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdReq });
        } else if (!passwordRegex.test(this.state.newPswrd)) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdValid });
        } else if (this.state.curentPswrd === this.state.newPswrd) {
            isCheck = false;
            this.setState({ newPswrdError: true, newPswrdErrorTxt: configJSON.newPswrdNotSame });
        }
        else {
            this.setState({ newPswrdError: false, newPswrdErrorTxt: "" });
        }

        if (this.state.confirmPswrd.length === 0) {
            isCheck = false;
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdReq });
        } else if (!passwordRegex.test(this.state.confirmPswrd)) {
            isCheck = false;
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdValid });
        } else if (this.state.newPswrd !== this.state.confirmPswrd) {
            this.setState({ confrimPswrdError: true, confrimPswrdErrorTxt: configJSON.confrimPswrdNotSame });
        }
        else {
            this.setState({ confrimPswrdError: false, confrimPswrdErrorTxt: "" });
        }
        if (isCheck) {
            this.changePasswordApi()
        }
    };

    changePasswordApi = async () => {
        this.setState({ isLoader: true })
        const body = {
            current_password: this.state.curentPswrd,
            new_password: this.state.newPswrd,
            confirm_password: this.state.confirmPswrd
        }
        this.changePswrdAPICallId = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.changePswrdApimethod,
            endPoint: configJSON.changePswrdEndPoint,
            body: body
        });
    };

    handleToggleNotifications = (name: string) => () => {
        this.setState((prevState) => {
            const newNotifications = { ...prevState.notifications, [name]: !prevState.notifications[name],};
            const allowAll = Object.values(newNotifications).every(Boolean);
            return { notifications: newNotifications, allowAll, };
        }, 
        this.updateNotificationPreference);
    };

    handleAllowAllToggle = () => {
        const allowAll = !this.state.allowAll;
        this.setState({
            allowAll,
            notifications: Object.keys(this.state.notifications).reduce(
            (accValue, keyValue) => {
                accValue[keyValue] = allowAll;
                return accValue;
            }, {} as { 
                [keyValue: string]: boolean 
            }),
        }, 
        this.updateNotificationPreference);
    };

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
    };

    handleDelete = () => {
        this.setState({ isDelete: !this.state.isDelete });
    };

    handlBrancheDelete = () => {
        this.setState({ isBranchDelete: !this.state.isBranchDelete });
    };

    changeAccountName = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        const regex = configJSON.regaxText;
    

        if (inputValue.length === 0) {
            this.setState({
                accountNameValue: inputValue,
                accountNameError: true,
                accountNameErrorTxt: configJSON.accountNameReq  
            });
        }
    
        else if (!regex.test(inputValue)) {
            this.setState({
                accountNameValue: inputValue,
                accountNameError: true,
                accountNameErrorTxt: "Invalid account name format"  // Customize error message
            });
        } 
        else {
            this.setState({
                accountNameValue: inputValue,
                accountNameError: false,
                accountNameErrorTxt: ""
            });
        }
    };
    

    changeAccountNumber = (event: { target: { value: string } }) => {
        const newValue = event.target.value.replace(/\D/g, ''); 
        let accountNumberError = false;
        let accountNumberErrorTxt = "";
    
        // Check if account number is empty
        if (newValue.length === 0) {
            accountNumberError = true;
            accountNumberErrorTxt = configJSON.accountNumberReq;  // Error message for required field
        }
        // Check if the account number already exists
        else if (this.state.allBankDetails.map((item) => item.attributes.account_number).includes(newValue)) {
            accountNumberError = true;
            accountNumberErrorTxt = "Account Number is already registered";  // Custom error message
        }
       
        else if (newValue.length < 9 || newValue.length > 18) {  
            accountNumberError = true;
            accountNumberErrorTxt = configJSON.accountNumberLengthReq;  
        }
    
        this.setState({
            accountNumberValue: newValue,
            accountNumberError,
            accountNumberErrorTxt
        });
    };

    changeAccountBankName = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        const regex = configJSON.regaxText;
    
        // Check if the input value is empty
        if (inputValue.length === 0) {
            this.setState({
                accountBankNameValue: inputValue,
                accountBankNameError: true,
                accountBankNameErrorTxt: configJSON.bankNameReq // Set error message for required field
            });
        }
        // Validate the input against the regex
        else if (!regex.test(inputValue)) {
            this.setState({
                accountBankNameValue: inputValue,
                accountBankNameError: true,
                accountBankNameErrorTxt: "Invalid bank name format" // Customize error message
            });
        } 
        // If valid, clear the error
        else {
            this.setState({
                accountBankNameValue: inputValue,
                accountBankNameError: false,
                accountBankNameErrorTxt: ""
            });
        }
    };
    

    changeBankAdrs = (event: { target: { value: string; }; }) => {

        const inputValue = event.target.value;

        if (inputValue.length === 0) {
            this.setState({
                bankAdrsValue: inputValue,
                bankAdrsError: true,
                bankAdrsErrorTxt: configJSON.bankAdrsReq // Set error message for required field
            });
        } 
        else { 
            this.setState({ 
                bankAdrsValue: inputValue,
                bankAdrsError: false,
                bankAdrsErrorTxt: ""
            });
        }
    };

    
    changeIban = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        // Check if the input value is empty
        if (inputValue.length === 0) {
            this.setState({
                ibanValue: inputValue,
                ibanError: true,
                ibanErrorTxt: configJSON.ibanReq // Set error message for required field
            });
        } else {
            this.setState({ 
                ibanValue: inputValue,
                ibanError: false,
                ibanErrorTxt: ""
            });
        }
    };

    changeShiftCode = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
    
        // Check if the input value is empty
        if (inputValue.length === 0) {
            this.setState({ shiftValue: inputValue, shiftError: true,shiftErrorTxt: configJSON.shiftCodeReq // Set error message for required field
            });
        } 
        else 
        {
            this.setState({ shiftValue: inputValue, shiftError: false, shiftErrorTxt: "" });
        }
    };

    onBankDetails = () => {
        let isCheck = true;
        const accountRegex = configJSON.accountNumberRegex;
        if (this.state.accountNameValue.length === 0) {
            isCheck = false;
            this.setState({ accountNameError: true, accountNameErrorTxt: configJSON.accountNameReq });
        } else {
            this.setState({ accountNameError: false, accountNameErrorTxt: "" });
        }

        if (this.state.accountNumberValue.length === 0) {
            isCheck = false;
            this.setState({ accountNumberError: true, accountNumberErrorTxt: configJSON.accountNumberReq });
        } else if (!accountRegex.test(this.state.accountNumberValue)) {
            isCheck = false;
            this.setState({ accountNumberError: true, accountNumberErrorTxt: configJSON.accountNumberLengthReq });
        } else {
            this.setState({ accountNumberError: false, accountNumberErrorTxt: "" });
        }

        if (this.state.accountBankNameValue.length === 0) {
            isCheck = false;
            this.setState({ accountBankNameError: true, accountBankNameErrorTxt: configJSON.bankNameReq });
        } else {
            this.setState({ accountBankNameError: false, accountBankNameErrorTxt: "" });
        }

        if (this.state.bankAdrsValue.length === 0) {
            isCheck = false;
            this.setState({ bankAdrsError: true, bankAdrsErrorTxt: configJSON.bankAdrsReq });
        } else {
            this.setState({ bankAdrsError: false, bankAdrsErrorTxt: "" });
        }

        if (this.state.ibanValue.length === 0) {
            isCheck = false;
            this.setState({ ibanError: true, ibanErrorTxt: configJSON.ibanReq });
        } else {
            this.setState({ ibanError: false, ibanErrorTxt: "" });
        }
        
        if (this.state.selectedCurrency === "none") {
            isCheck = false;
            this.setState({ currencyError: true, currencyErrorTxt: "Please select currency" });
        } else {
            this.setState({ currencyError: false, currencyErrorTxt: "" });
        }
        return isCheck;
    };

    // for manage branch
    openBranchModal = (id?: number) => {
        if (id) {
            const filteredData = this.state.allBranchData.filter(item => item.attributes.id === id);
            this.setState((prevState) => ({
                branchModal: !prevState.branchModal,
                branchEditID: id,
                isBranchEdit: true,
                branchName: filteredData[0].attributes.branch_name,
                branchMobileNumber: filteredData[0].attributes.phone_number.toString(),
                branchEmailID: filteredData[0].attributes.email,
                branchNameError: "",
                branchCountryCode: filteredData[0].attributes.country_code.toString(),
                branchAgent: filteredData[0].attributes.managed_by,
                branchEmailError: "",
                branchAgentError: "",
                branchMobileNumberError: "",
            }))
        }
        else {
            this.setState((prevState) => ({
                branchModal: !prevState.branchModal, branchEditID: 0,
                isBranchEdit: false,
                branchName: "",
                branchCountryCode: "91-IN",
                branchMobileNumber: "",
                branchAgent: "",
                branchMobileNumberError: "",
                branchAgentError: "",
                branchEmailError: "",
                branchEmailID: "",
                branchNameError: ""
            }))
        }
    }

    changeBranchName = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        if (inputValue.trim() === "") {
            this.setState({ 
                branchNameError: "Branch name is required" 
            });
        } else if (configJSON.Character30Limit.test(inputValue)) {
            this.setState({
                branchNameError: "Branch name  must be less then 30 characters"
            });
        } else {
            this.setState({ 
                branchNameError: "" 
            });
        }
        this.setState({ 
            branchName: inputValue 
        });
    };
    

    changeBranchEmail = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;
        const emailRegex = configJSON.emailRegex;
        if (inputValue === "") {
            this.setState({
                branchEmailError: "Email is required.",
                branchEmailID: inputValue
            });
        } else if (!emailRegex.test(inputValue)) {
            this.setState({
                branchEmailError: "Please enter a valid email address.",
                branchEmailID: inputValue
            });
        } else {
            this.setState({
                branchEmailError: "",
                branchEmailID: inputValue
            });
        }
    };

    changeBranchCountryCode = (event: SelectEventType) => {
        this.setState({ 
            branchCountryCode: event.target.value as string,
            stateError: false
        });
    };

    changeBranchMobile = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value.trim();
        if (inputValue === "") {
            this.setState({ 
                branchMobileNumberError: "Branch Number is required" 
            });
        } else if (!/^\d{10}$/.test(inputValue)) {
            this.setState({ 
                branchMobileNumberError: "Branch Number must be 10 digits" 
            });
        } else {
            this.setState({ 
                branchMobileNumberError: "" 
            });
        }
        this.setState({ 
            branchMobileNumber: inputValue 
        });
    };

    getCountryCode = async () => {
        this.getCountryCodeAPICallID = await this.apiCall({
          contentType: configJSON.searchApiContentType,
          method: configJSON.httpGetMethod,
          endPoint: configJSON.getCountryCode
        });
      }
    
    setCountryCodes = (responseJson: any) => {
        this.setState({ countryCodes: responseJson })
        const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
        this.setState({ branchCountryCode: `${defaultCountry?.country_code}-${defaultCountry?.alpha2}` })
    };

    fetchAllBranches = async () => {
        this.setState({ 
            isLoader: true
        });
        this.fetchAllBranchAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.fetchAllBranch
        });
        this.setState({ isLoader: false });
    };

    fetchAllBranchSuccessCall = (responseJson: any) => {
        this.setState({
            allBranchData:responseJson.data
        })
    };

    handleSubmitBranch = async (event: any) => {
        event.preventDefault();

        const { branchName,
            branchEmailID,
            branchMobileNumber,
            branchAgent,
            branchCountryCode
        } = this.state;

        let isValid = true;

        const mobileRegexNewOne = /^\d{10}$/;

        if (!branchName.trim()) {
            this.setState({ branchNameError: "Branch name is required" });
            isValid = false;
        } else {
            this.setState({ branchNameError: "" });
        }
        const emailRegex = configJSON.emailRegex;
        if (!emailRegex.test(branchEmailID)) {
            this.setState({ branchEmailError: "Invalid email format" });
            isValid = false;
        } else {
            this.setState({ branchEmailError: "" });
        }
        const mobileRegex = configJSON.mobileReagx;
        const branchMobileNumberStr = branchMobileNumber.toString();
        if (branchMobileNumberStr.trim() === "") {
            this.setState({ branchMobileNumberError: "Mobile number can't be blank." });
            isValid = false;
        }
        else if(!mobileRegexNewOne.test(branchMobileNumberStr)) {
            this.setState({ branchMobileNumberError: "Mobile number should be exactly 10 digits" });
            isValid = false;
        } 
        else {
            this.setState({ branchMobileNumberError: "" });
        }
        if (!branchAgent.trim()) {
            this.setState({ branchAgentError: "Managed by can't be empty" });
            isValid = false;
        } else {
            this.setState({ branchAgentError: "" });
        }
        if (isValid) {
            const collectBranchData = {
                "data": {
                    "attributes": {
                        "branch_name": branchName,
                        "email": branchEmailID,
                        "country_code": branchCountryCode,
                        "phone_number": branchMobileNumber,
                        "managed_by": branchAgent
                    }
                }
            }
            this.setState({ isLoader: true });
            this.saveBranchAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.changePswrdApimethod,
                endPoint: configJSON.saveBranch,
                body: collectBranchData
            });
            this.setState({ isLoader: false });
        }
    };

    handleChangeBranchAgent = (event: { target: { value: string; }; }) => {
        const inputValue = event.target.value;


        if (inputValue.trim() === "") {
            this.setState({ 
                branchAgentError: "Branch Agent is required" 
            });
        } else if (configJSON.Character30Limit.test(inputValue)) {
            this.setState({
                branchAgentError: "Branch Agent must be less then 30 characters"
            });
        } else {
            this.setState({ 
                branchAgentError: "" 
            });
        }

        this.setState({ 
            branchAgent: inputValue
        });
    };

    resetBranchFormData = () => {
        if(this.state.isBranchDelete){
            toast.success("Deleted Successfully")
        }
        else if(this.state.isBranchEdit){
            toast.success("Update Successfully")
        }else{
            toast.success("Add Successfully")
        }
        this.setState({
            branchModal:false,
            branchAgent:"",
            branchEmailID:"",
            branchCountryCode:"91-IN",
            branchMobileNumber:"",
            branchAgentError:"",
            branchEmailError:"",
            branchName:"",
            branchEditID:0,
            isLoader:false,
            branchMobileNumberError:"",
            branchNameError:"",
            isBranchEdit:false,
            isBranchDelete: false
        })

        this.fetchAllBranches()
    }

    handleSubmitBranchEdit = async (event: any) => {
        event.preventDefault();

        const { branchName, branchEmailID, branchMobileNumber, branchAgent, branchCountryCode } = this.state;

        let isValid = true;
        
        const mobileRegexNew = /^\d{10}$/;

        const mobileRegex = configJSON.mobileReagx;
        const branchMobileNumberStr = branchMobileNumber.toString();

        if (branchMobileNumberStr.trim() === "") {
            this.setState({ branchMobileNumberError: "Mobile number can't be blank." });
            isValid = false;
        }

            else if (!mobileRegexNew.test(branchMobileNumberStr)) {
                this.setState({ branchMobileNumberError: "Mobile number must be exactly 10 digits." });
                isValid = false;
            } 

         else {
            this.setState({ branchMobileNumberError: "" });
        }

        if (!branchAgent.trim()) {
            this.setState({ branchAgentError: "Please select a member" });
            isValid = false;
        } else {
            this.setState({ branchAgentError: "" });
        }
        
        const emailRegex = configJSON.emailRegex
        if (!emailRegex.test(branchEmailID)) {
            this.setState({ branchEmailError: "Invalid email format" });
            isValid = false;
        } else {
            this.setState({ branchEmailError: "" });
        }
        
        if (!branchName.trim()) {
            this.setState({ branchNameError: "Branch name is required" });
            isValid = false;
        } else {
            this.setState({ branchNameError: "" });
        }

        if (isValid) {

            const collectBranchData = {
                "data": {
                    "attributes": {
                        "managed_by": branchAgent,
                        "phone_number": branchMobileNumber,
                        "branch_name": branchName,
                        "country_code": branchCountryCode,
                        "email": branchEmailID,
                    }
                }
            }
            this.setState({ isLoader: true });
            this.saveBranchAPICallID = await this.apiCall({
                endPoint: `${configJSON.saveBranch}/${this.state.branchEditID}`,
                contentType: configJSON.changePswrdContentType,
                method: configJSON.httpPutMethod,
                body: collectBranchData
            });
            this.setState({ isLoader: false });
        }
    };

    handleDeleteBranch = async () => {
        this.setState({ isLoader: true });
        this.saveBranchAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.deleteMethodType,
            endPoint: `${configJSON.saveBranch}/${this.state.branchEditID}`,
        })
        this.setState({ isLoader: false });
    }

    validateMobileNo = () => {
        if (this.state.branchMobileNumber && this.state.branchCountryCode) {
            this.validateMobile(this.state.branchMobileNumber, this.state.branchCountryCode);
        }
    }

    validateMobile = async (mobileno: string, countrycode: string) => {
        this.validateMobileNoAPICallID = await this.apiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.validateMobileNumber}?mobile=${mobileno}&country_code=${countrycode}`
        });
    }

    setContactErrors = (responseJson: any) => {
        const errorMessage = responseJson.errors?.[0]?.message
        this.setState({branchMobileNumberError:errorMessage})
    }
    


        // bank information

    fetchAllBankDetails = async () => {
        this.setState({ 
            isLoader: true
        });
        this.fetchAllBanksAPICallID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.fetchAllBankDetails
        });
    };
    
        fetchAllBankSuccessCall = (responseJson: any) => {
            this.setState({allBankDetails:responseJson.data, isLoader:false})
        };
    
        modelOpen = (id?: number) => {
            if(id){
                const filteredData = this.state.allBankDetails.filter(detail => Number(detail.id) === id);
                this.setState((prevState) => ({
                    isModal: !prevState.isModal,
                    accountNameValue: filteredData[0].attributes.bankholder_name,
                    accountNumberValue: filteredData[0].attributes.account_number,
                    accountBankNameValue: filteredData[0].attributes.bank_name,
                    bankAdrsValue: filteredData[0].attributes.bank_address,
                    ibanValue: filteredData[0].attributes.iban,
                    shiftValue: filteredData[0].attributes.swiftcode,
                    bankEditID: id,
                    isBankEdit: true,
                    selectedCurrency:filteredData[0].attributes.currency_preference,
                }))
            }
            else {
                this.setState({
                    isModal: !this.state.isModal,
                    accountNameValue: "",
                    accountNumberValue: "",
                    accountBankNameValue: "",
                    bankAdrsValue: "",
                    ibanValue: "",
                    shiftValue: "",
                    bankEditID: 0,
                    bankEditName:"",
                    isBankEdit: false,
                    selectedCurrency:"none",
                    isLoader:false
                })
            }
            this.setState({
                accountNameError:false,
                accountNumberError:false,
                accountBankNameError:false,
                bankAdrsError:false,
                ibanError:false,
                shiftError:false,
                currencyError:false,
                bankErrorResponse:[]
            })
        }
    
        updateBankInfo = async () => {
            const isValid = this.onBankDetails();
    
            if (isValid) {
                const filteredData = this.state.allBankDetails.filter(detail => Number(detail.id) === this.state.bankEditID);
                const body = {
                    bankholder_name: this.state.accountNameValue,
                    account_number: this.state.accountNumberValue,
                    bank_name: this.state.accountBankNameValue,
                    bank_address: this.state.bankAdrsValue,
                    iban: this.state.ibanValue,
                    swiftcode: this.state.shiftValue,
                    primary: filteredData[0].attributes.primary,
                    currency_preference: this.state.selectedCurrency
                }
        
                this.setState({ 
                    isLoader: true
                });
                this.saveBankInfoAPICallID = await this.apiCall({
                    contentType: configJSON.changePswrdContentType,
                    method: configJSON.httpPutMethod,
                    endPoint: `${configJSON.updateBankDetail}/${this.state.bankEditID}`,
                    body: body
                });
            }
        }
    
        setBankResponseError = (responseJson: any) => {
            this.setState({
                bankErrorResponse:responseJson.errors
            })
        };

        setBranchResponseError = (responseJson: any) => {
            toast.error(responseJson.errors.message)
        };
    
        resetBankFormData = () => {
            this.setState({
                isModal: false,
                accountNameValue: "",
                shiftValue: "",
                bankEditID: 0,
                bankEditName:"",
                accountNumberValue: "",
                accountBankNameValue: "",
                bankAdrsValue: "",
                ibanValue: "",
                isBankEdit: false,
                bankErrorResponse:[],
                isLoader: false,
                bankAdrsError:false, 
                ibanError:false, 
                selectedCurrency:"none",
                accountNameError:false, 
                accountNumberError:false, 
                accountBankNameError:false, 
                currencyError:false,
                shiftError:false,
                isDelete:false
            })
    
            this.fetchAllBankDetails()
        }
    
        saveBankInfo = async () => {
            const isValid = this.onBankDetails();
            if (isValid) {
                const body = {
                    account_number: this.state.accountNumberValue,
                    bank_name: this.state.accountBankNameValue,
                    primary: this.state.allBankDetails.length === 0 ? true : false,
                    swiftcode: this.state.shiftValue,
                    bank_address: this.state.bankAdrsValue,
                    currency_preference: this.state.selectedCurrency,
                    iban: this.state.ibanValue,
                    bankholder_name: this.state.accountNameValue
                }
    
                this.setState({ isLoader: true });
                this.saveBankInfoAPICallID = await this.apiCall({
                    contentType: configJSON.changePswrdContentType,
                    method: configJSON.httpPostMethod,
                    endPoint: configJSON.updateBankDetail,
                    body: body
                });
            }
        }
    
        updateBankPrimary = async (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
            const body = {
                primary: event.target.checked,
            }
    
            this.setState({ 
                isLoader: true, isBankEdit:true, UpDatePrimary: true
            });
            this.saveBankInfoAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.httpPutMethod,
                endPoint: `${configJSON.updateBankDetail}/${id}`,
                body: body
            });
        }
        
    
        handleChangeCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
            const selectedValue = event.target.value as string;
        
            if (selectedValue === "none") {
                // Assuming "none" indicates an invalid selection
                this.setState({
                    selectedCurrency: selectedValue,
                    currencyError: true,
                    currencyErrorTxt: "Please select a currency"
                });
            } else {
                this.setState({
                    selectedCurrency: selectedValue,
                    currencyError: false,
                    currencyErrorTxt: ""
                });
            }
        };
        
    
        handleDeleteID = (id:number, bankName: string) => {
            this.setState({ 
                isDelete: true, 
                bankEditName: bankName,
                bankEditID:id 
            });
        }

        handleDeleteBranchID = (id:number, bankName: string) => {
            this.setState({ isBranchDelete: true, branchName: bankName,branchEditID:id });
        }
    
        deleteBankInfo = async () => {
            this.setState({ 
                isLoader: true
            });
            this.saveBankInfoAPICallID = await this.apiCall({
                contentType: configJSON.changePswrdContentType,
                method: configJSON.deleteMethodType,
                endPoint: `${configJSON.updateBankDetail}/${this.state.bankEditID}`,
            });
        }

        setBankMessages = (responseJson: any) => {
            if (this.state.UpDatePrimary) {
                toast.success("Update Successfully")
            }
        else if (this.state.bankEditID == 0) {
            toast.success("Add Successfully")
             this.getProfileAccount();
        } else {
            if (this.state.isDelete) {
                toast.success("Deleted Successfully")
            } else {
                toast.success("Update Successfully")
            }
        }
        this.resetBankFormData()
        this.setState({isAlert: false, alertMsg:responseJson.meta.message, UpDatePrimary:false ,alertType:"success"})
    }
    
        // bank information end
  
    fetchAllDocuments = async () => {
    this.setState({ isLoader: true });
    this.fetchAllDocumentsID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.getAllDocuments
    });
    };
    fetchAllDocumentsSuccessCall = (responseJson: any) => {
      
        this.setState({allDocumentsData:responseJson.data})
    };
    // fetch documents end

   
     handleFileChangeDoc = async (
        event: React.ChangeEvent<HTMLInputElement>,
        documentCategory: string
    ) => {
        const file = this.getFileFromEvent(event);
        if (!file) return;
    
        const fileExtension = this.getFileExtension(file);
        if (!this.isAcceptedFormat(fileExtension)) {
            alert('Please upload a file in .png, .jpg, .pdf, or .doc format.');
            return;
        }
    
        this.setState({ selectedFileNew: file, isLoader: true }, async () => {
            if (this.state.selectedFileNew) {
                try {
                    const formData = this.createFormData(this.state.selectedFileNew);
                    const apiEndpoint = this.getApiEndpoint(documentCategory);
                    if (apiEndpoint) {
                        this.uploadDocumentID = await this.uploadDocument(apiEndpoint, formData);
                        await this.fetchAllDocuments();
                    }
                } catch (error) {
                    console.error("Error uploading document:", error);
                } finally {
                    this.setState({ isLoader: false });
                }
            }
        });
    };
    
    
    getFileFromEvent = (event: React.ChangeEvent<HTMLInputElement>): File | null => {
        return event.target.files ? event.target.files[0] : null;
    };
    
    
    getFileExtension = (file: File): string | undefined => {
        return file.name.split('.').pop()?.toLowerCase();
    };

    
    isAcceptedFormat = (fileExtension?: string): boolean => {
        const acceptedFormats = ['png', 'jpg', 'pdf', 'doc', 'docx'];
        return fileExtension ? acceptedFormats.includes(fileExtension) : false;
    };
    
    createFormData = (file: File): FormData => {
        const formData = new FormData();
        formData.set("doc", file);
        return formData;
    };
    
    getApiEndpoint = (documentCategory: string): string => {
        switch (documentCategory) {
            case 'agent_docs':
                return configJSON.uploadlDocuments;
            case 'personal_id':
                return configJSON.uploadPersonalId;
            case 'business_registration_certificate':
                return configJSON.uploadBuisnessDocs;
            default:
                return '';
        }
    };
    
    uploadDocument = async (apiEndpoint: string, formData: FormData): Promise<any> => {
        return this.apiCall({
            method: configJSON.changePswrdApimethod,
            endPoint: apiEndpoint,
            body: formData,
            type: "formData"
        });
    };

  
    uploadDocumentSuccessCall = (responseJson: any) => {
        this.setState({ uploadedDocumentData: responseJson.data, isLoader: false });
        this.fetchAllDocuments();
        this.setState({ isLoader: false });
        this.getProfileAccount();
    };
     getStatusIcon = (status: string) => {
        switch (status) {
          case "pending":
            return <HourglassEmptyIcon style={{ color: '#FFA500' }} />; // Orange icon for pending
          case "fix_required":
            return <ReportProblemIcon style={{ color: '#FF0000' }} /> 
          case "done":
            return <CheckCircleIcon style={{ color: '#4CAF50' }} />; // Green icon for done
          default:
            return null;
        }
      };

    handleDeleteDoc = async (
        event: React.MouseEvent<HTMLDivElement>, 
        id: string
      ) => {
        if(this.state.profileStatus === "fully_approved") return
        try {
            this.deleteDocumentID = await this.apiCall({
                method: configJSON.deleteMethodType,
                endPoint: `${configJSON.deleteDocuments}/${id}`,
            });
            await this.fetchAllDocuments();
        } catch (error) {
            console.error("Error deleting document:", error);
        } finally {
            this.setState({ isLoader: false });
        }
      }
    
      deleteDocumentSuccessCall= () => {
        this.fetchAllDocuments();
        this.setState({ isLoader: false });
        this.getProfileAccount();
    };

    handleRowClick = (rowId: number) => {
        this.setState((prevState) => ({
            expandedRowId: prevState.expandedRowId === rowId ? null : rowId,
        }));
    };
    // end manage branch

    
  // personal info started

  updateNotificationPreference = async () => {
    const body = {
        "preferred_notification": this.state.notifications,
        "allow_all": this.state.allowAll
      }
    this.setState({ isLoader: true });
    this.saveNotificationPreference = await this.apiCall({
        contentType: configJSON.changePswrdContentType,
        method: configJSON.httpPutMethod,
        endPoint: configJSON.updateNotificationPreference,
        body: body
    });
    this.setState({ isLoader: false });
}

saveProfileAccount = async () => {
    const ContryValue = this.state.countryCodes.filter((item)=>{
        return item.alpha2 === this.state.countryValue
    })
    
    let userDetails = await getStorageData("userDetails")
    const body = {
        "data":{
            "attributes":{
                "email": this.state.memberEmailTxtValue,
                "first_name": this.state.memberTxtValue,
                "marital_status": this.state.maritalValue,
                "phone_number": this.state.mobileNumberValue,
                "country": this.state.countryValue,
                "state": this.state.stateValue,
                "city": this.state.cityValue,
                "date_of_birth": this.state.birthDateValue,
                "gender": this.state.genderValue,
                "marriage_anniversary": this.state.marriageDateValue,
                "country_code": `${ContryValue[0].country_code}-${ContryValue[0].alpha2}`
            }
        }
    }

    this.setState({ isLoader: true });
    this.saveProfileAccountAPICallID = await this.apiCall({
        contentType: configJSON.changePswrdContentType,
        method: configJSON.httpPutMethod,
        endPoint: configJSON.getProfileEndPoint + userDetails,
        body: body
    });
};

handleUpdateProfile = (responseData:any) => {
    this.getProfileAccount();
    this.setState({isLoader: false, isAlert: true, alertMsg:responseData.meta.message, alertType:"success"})
}
// personal info ended

    // agreement section start

    fetchAllAgreements = async () => {
        this.setState({ isLoader: true });
        this.fetchAllAgreementsID = await this.apiCall({
            contentType: configJSON.changePswrdContentType,
            method: configJSON.staffApimethod,
            endPoint: configJSON.fetchAllAgreementsDoc
        });
    };
    fetchAllAgreementsSuccessCall = (responseJson: any) => {
        this.setState({allAgreementsData:responseJson?.agreement?.data})
    };
    handleDeleteDocAgree = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: string
      ) => {
        try {
            this.deleteAgreementID = await this.apiCall({
                method: configJSON.deleteMethodType,
                endPoint: `${configJSON.deleteAgentAgreement}/${id}`,
            });
        } catch (error) {
        } finally {
            this.setState({ isLoader: false });
        }
      }

      deleteAgreementSuccessCall= () => {
        this.fetchAllAgreements();
        this.setState({ isLoader: false });
        this.getProfileAccount();
    };


    handleFileChangeDocAgreement = async (
        event: React.ChangeEvent<HTMLInputElement>,
        agreementId: string
    ) => {
        const file = this.getFileFromEvent(event);
        if (!file) return;
    
        const fileExtension = this.getFileExtension(file);
        if (!this.isAcceptedFormat(fileExtension)) {
            alert('Please upload a file in .png, .jpg, .pdf, or .doc format.');
            return;
        }
        const formData = new FormData();
        formData.append("digital_signature", file);
        formData.append("admin_agreement_id",agreementId);
    
        this.setState({ selectedAgreementFile: file, isLoader: true }, async () => {
            if (this.state.selectedAgreementFile) {
                try {
                    this.uploadAgreementsID = await this.apiCall({
                        method: configJSON.changePswrdApimethod,
                        endPoint:configJSON.uploadlAgreementDoc ,
                        type: configJSON.bankdetailsType,
                        body: formData,
                    });
                    await this.fetchAllAgreements();
                } catch (error) {
                } finally {
                    this.setState({ isLoader: false });
                }
            }
        });
    };
    
    uploadAgreementsSuccessCall = (responseJson: any) => {

        this.fetchAllAgreements();
        this.setState({allAgreementsData:responseJson?.agreement?.data,isLoader: false})
         this.getProfileAccount();        
    };

    getAllStaffData = async () => {
        this.setState({ isLoader: true });
        this.GetStaffApiCallId = await this.apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: "/account_block/accounts/all_staff"
        });
    };
    
    OpenCloseMangeStaff = () =>{
        this.setState({
            MangeStaffModel: !this.state.MangeStaffModel,
            branchMobileNumberError: "",
            branchCountryCode: "91-IN",
            MangeStaffFormData: {
                name: '',
                lastName: '',
                email: '',
                mobile: '',
                role: '',
                designation: ''
            },
            StaffId: ``,
        })
    }

    AddNewStaffSchema = () => {
        return Yup.object().shape({
            name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').required("Name is required"),

            lastName: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed').max(30, 'maximum 30 characters are allowed').required("Last name is required"),

            email: Yup.string().email("Please enter valid email").required("*Email is required"),

            role: Yup.string().required("Role is required"),

            designation: Yup.string().max(30, 'maximum 30 characters are allowed').matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed').required("Designation is required"),
            
            mobile: Yup.string().required("Phone number is required"),
        })
    }

    onChangeOfMangeStaff = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 
        name: string, 
        SetFieldValue: (field: string,
            value: string, shouldValidate?:
                boolean) => void
    ) => {
        if (name === "mobile") {
            const inputValue = e.target.value.replace(/\D/g, "").trim();
            SetFieldValue(name, inputValue)
            this.setState({ MangeStaffFormData: {...this.state.MangeStaffFormData, [name]: inputValue},branchMobileNumberError:"" })
            this.validateMobile(inputValue, this.state.branchCountryCode);
        } else {
            SetFieldValue(name, e.target.value)
            this.setState({ MangeStaffFormData: { ...this.state.MangeStaffFormData, [name]: e.target.value } })
        }
    }

    OnChangeMangeStaffRadioButton = (value:string,name: string, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) =>{
        SetFieldValue(name, value)
        this.setState({ MangeStaffFormData: { ...this.state.MangeStaffFormData, [name]: value } })
    }

    CreateMangeStaff = async (values: { name: string, lastName: string, email: string, mobile: string, role: string, designation: string }) => {
        this.setState({ isLoader: true, MangeStaffModel: !this.state.MangeStaffModel, });
        this.AddNewStaffApiCall = await this.apiCall({
            method: this.state.isMangeStaffEdit ? "PATCH" : "POST",
            contentType: "application/json",
            endPoint: this.state.isMangeStaffEdit ? `/account_block/accounts/update_staff?id=${this.state.StaffId}` : "account_block/accounts/add_staff",
            body: {
                data: {
                    attributes: {
                        first_name: values.name,
                        last_name: values.lastName,
                        email: values.email,
                        country_code: this.state.branchCountryCode,
                        phone_number: values.mobile,
                        role: values.role,
                        designation: values.designation
                    }
                }
            }
        });
    }

    EditStaffModelOpen = (item: {
        id: number;
        type: string;
        attributes: { last_name: string; role: string; first_name: string; email: string; phone_number: number; country_code: number; status: string; designation: string };
    }) => {
        const countryCode = this.state.countryCodes.filter((Filteritem)=>{ if (item.attributes.country_code.toString() === Filteritem.country_code.toString()) { return item } })
        this.setState({
            MangeStaffModel: !this.state.MangeStaffModel,
            branchCountryCode: `${countryCode[0].country_code}-${countryCode[0].alpha2}`,
            branchMobileNumberError: "",
            MangeStaffFormData: {
                mobile: `${item.attributes.phone_number}`,
                name: item.attributes.first_name,
                lastName: item.attributes.last_name,
                designation: item.attributes.designation,
                role: item.attributes.role,
                email: item.attributes.email
            },
            StaffId: `${item.id}`,
            isMangeStaffEdit: true
        })
    }
    
    handleSendForReview = async() => {
        this.sendForReviewApiCallId = await this.apiCall({
            method: configJSON.httpPostMethod,
            endPoint: configJSON.sendForRevieEndPoint
        });
    };

    handleSuccessSendForReview = async(responseJson:SendForReviewApiResponse)=>{
        if(responseJson.errors){
           return toast.error(responseJson.errors.message)
        }
         this.getProfileAccount();
        toast.success("Your profile is now under review");
    
    };

    // agreement section end

    openReassignPopUp = () => {
        this.setState({ openReassign: !this.state.openReassign });
        this.setState({ associateValue:"none", studentData:[], reassignData:{students:[], agents:[]}, reassignAgentError:"", reassignStudentError:"" });
    };

    handleSelectionChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        this.setState({ studentData: newValue, reassignStudentError:"" })
    };

    handleRemoveItem = (item:any) => {
        this.setState((prevState) => ({
            studentData: prevState.studentData.filter(
                (option) => option.student_id !== item
            ),
        }));
    }

    handleBranchChange = (event: SelectEventType) => {
        this.setState({ associateValue: event.target.value, reassignAgentError:"" });
      };

    handelPropsChange = (e: unknown[]) => {
        if (e.length !== 0) {
          this.setState({
            staffRowData: e as {
                id: number;
                type: string;
                attributes: {
                    first_name: string;
                    last_name: string;
                    email: string;
                    phone_number: number;
                    country_code: number;
                    role: string;
                    status: string;
                    designation: string;
                    student_count: number;
                    application_count: number
                };
            }[]
          })
        }
      }

    getAllStudentListForReassign = async (agentID: number) => {
        this.setState({isLoader: true})
        this.getAllListStudentAPICallId = await this.apiCall({
            method: configJSON.httpGetMethod,
            endPoint: `${configJSON.getAllStudentListForReassign}?id=${agentID}`
        });
    };

    handleAllListofStudentAndCouns = (responseData:DataForReassign) => {
        this.setState({isLoader: false, openReassign: true, reassignData: responseData})
    }

    reassignStudent = async () => {
        const { studentData, associateValue } = this.state;
        const isValidStudentData = studentData.length > 0;
        const isValidAssociateValue = associateValue !== "none";
        
        if (!isValidStudentData) {
            this.setState({ reassignStudentError: "Please select student from the list" });
            return;
        }
        
        if (!isValidAssociateValue) {
            this.setState({ reassignAgentError: "Please select agent/counsellor" });
            return;
        }
        
        this.setState({ isLoader: true });
        const idArray: string[] = [];
        const studentIdArray: string[] = [];
        
        studentData.forEach(({ id, student_id }) => {
            idArray.push(id.toString());
            studentIdArray.push(student_id);
        });
        
         const body = {
            student_id: studentIdArray,
            id: idArray,
            agent_id: associateValue
        } 

        this.reassignAllAPICallId = await this.apiCall({
            endPoint: configJSON.reassignAllStudents,
            contentType: configJSON.changePswrdContentType,
            body: body,
            method: configJSON.httpPutMethod
        });
    }

    handleReassignStudent = (responseData:any) => {
        if(responseData.errors) {
            toast.error(responseData.errors.message)
        }

        if(!responseData.errors) {
            this.setState({ isLoader: false, openReassign:false, associateValue:"none", studentData:[], reassignData:{students:[], agents:[]}, reassignAgentError:"", reassignStudentError:"" });
            toast.success(responseData.message)
            this.getAllStaffData()
        }
    }

    ActiveDeactive = async (id:number) => {
        const body = {
            id: id
        } 
        this.activateDeactivateAPICallId = await this.apiCall({
            endPoint: configJSON.activeDeactive,
            contentType: configJSON.changePswrdContentType,
            body: body,
            method: configJSON.httpPutMethod
        });
    }

    handleActivateDeactivate = (responseData:any) => {
        toast.success(responseData.message)
        this.getAllStaffData()
    }
    // Customizable Area End
}