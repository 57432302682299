// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { NavigationStackProp } from "react-navigation-stack";
import { Country } from 'country-state-city';
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}

export interface SelectEventType {
  target: {
    value: string | unknown;
  }
};

interface FilteredData {
  name: string;
};

interface CountryInterfaceData {
  name: string;
  ICountry: [];
  isoCode: string;
  flag: string;
  phonecode: string;
  currency: string;
  latitude: string;
  longitude: string;
  timezones: {
    zoneName: string;
    gmtOffset: string;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
  };
};

interface EmployeeDataType {
  id:string;
  attributes:{
    email: string;
    first_name:string;
    last_name:string;
  }
}

interface SessionType {
  id:string;
  months:string[];
}
interface CoursesDataType {
  id:string;
  program_name:string;
  sessions:SessionType[];
}

export interface CollegeDataType {
  id:string;
  attributes:{
    university_name:string;
    courses:CoursesDataType[];
  }
}

export interface UserDetails {
  attributes: {
    first_name: string;
    role:string;
    image: {
      url: string;
    };
  }
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
}

interface ValidResponseType {
  message: string;
  data: UserDetails[];
  meta: {
    message: string;
  }
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationStackProp;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  universityId:string;
  courseId:string;
  sessionId:string;
  showUniversityData:boolean;
  receivedRequests: IRequest[];
  employeesData:EmployeeDataType[];
  createdByData:EmployeeDataType[];
  coursesData:CoursesDataType[];
  inTakeData:{id:string;sessionName:string}[];
  yearData:number[];
  rejectText: string;
  collegeData:CollegeDataType[];
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  employeeValue: string;
  employeeValueError: boolean;
  employeeValueErrorTxt: string;
  preferredType: string;
  countryValue: string | unknown;
  stateData: Array<FilteredData>;
  countryErrorTxt: string;
  countryError: boolean;
  countryData: Array<string>;
  preferCollegeValue: string;
  preferCollegeValueError: boolean;
  preferCollegeValueErrorTxt: string;
  preferCourseValue: string;
  preferCourseValueError: boolean;
  preferCourseValueErrorTxt: string;
  createdValue: string | unknown;
  createdValueError: boolean;
  createdValueErrorTxt: string;
  remarkTxtValue: string;
  remarkTxtValueError: boolean;
  remarkTxtValueErrorTxt: string;
  toggleDrawer: boolean;
  inTakeValue: string;
  inTakeValueError: boolean;
  inTakeValueErrorTxt: string;
  preferredTypeError: boolean;
  preferredTypeErrorTxt: string;
  intakeYearTxtValue: string | unknown;
  intakeYearTxtValueError: boolean;
  intakeYearTxtValueErrorTxt: string;
  userToken:string;
  studentId:string;
  successPopUp:boolean;
  userDetails: UserDetails;
  studentIDGen: string
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeadManagementApplicationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  employeeApiCallId:string = "";
  countriesApiCallId:string = "";
  countriesRelatedApiCallId:string = "";
  createdByApiCallId:string = "";
  addApplicationApiCallId:string = "";
  getProfileAPICallId: string = "";
  ApplyNowShortlistButtonApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token: "",
      showUniversityData:false,
      employeesData:[],
      receivedRequests: [],
      collegeData:[],
      coursesData:[],
      inTakeData:[],
      yearData:[],
      universityId:"",
      studentId:"",
      courseId:"",
      sessionId:"",
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      employeeValue: "none",
      employeeValueError: false,
      employeeValueErrorTxt: "",
      preferredType: "",
      countryValue: "none",
      stateData: [],
      countryErrorTxt: "",
      countryError: false,
      countryData: Country.getAllCountries() as never,
      preferCollegeValue: "none",
      preferCollegeValueError: false,
      preferCollegeValueErrorTxt: "",
      preferCourseValue: "none",
      preferCourseValueError: false,
      preferCourseValueErrorTxt: "",
      createdValue: "none",
      createdValueError: false,
      createdValueErrorTxt: "",
      remarkTxtValue: "",
      remarkTxtValueError: false,
      remarkTxtValueErrorTxt: "",
      toggleDrawer: false,
      inTakeValue: "none",
      inTakeValueError: false,
      inTakeValueErrorTxt: "",
      preferredTypeError: false,
      preferredTypeErrorTxt: "",
      intakeYearTxtValue: "none",
      intakeYearTxtValueError: false,
      intakeYearTxtValueErrorTxt: "",
      userToken:"",
      createdByData:[],
      successPopUp:false,
      userDetails: {} as UserDetails
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.profileApi(responseJson, apiRequestCallId);
      
      const handleApiResponse = (apiRequestCallId, responseJson) => {
        const updateState = (key, data) => this.setState({ [key]: data });
      
        const handleDataUpdate = (key, data) => {
          if (data) updateState(key, data);
        };
      
        const handlers = {
          [this.employeeApiCallId]: () => handleDataUpdate("employeesData", responseJson.students.data),
          [this.countriesApiCallId]: () => handleDataUpdate("countryData", responseJson.countries),
          [this.countriesRelatedApiCallId]: () => handleDataUpdate("collegeData", responseJson.university.data),
          [this.createdByApiCallId]: () => handleDataUpdate("createdByData", responseJson.agent_account.data),
          [this.addApplicationApiCallId]: () => {
            if (responseJson.data) updateState("successPopUp", true);
          },
          [this.ApplyNowShortlistButtonApiCallId]: () => {
            const { errors } = responseJson;
            if (Array.isArray(errors) && errors.length) {
              toast.warning(errors[0]);
            } else {
              updateState("successPopUp", true);
            }
          },
        };
      
        handlers[apiRequestCallId]?.();
      };
      
      handleApiResponse(apiRequestCallId, responseJson);
      /*istanbul ignore next*/
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    const token = await getStorageData("token")
    this.setState({userToken:token})
    const currentYear = new Date().getFullYear();
    const nextFiveYears = Array.from({ length: 5 }, (v, i) => currentYear + i);
    this.setState({yearData:nextFiveYears})
    this.getEmployees();
    this.getCountries();
    this.getCreatedBy()
    this.getProfileAccount();
    if(window.location.pathname === "/edit-application"){
      this.setState({showUniversityData:true})
    }
  };
  
  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const homeNavigation = new Message(getName(MessageEnum.NavigationMessage));
    homeNavigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    homeNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(homeNavigation);
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onLeadManagementTable = () => {
    const LeadManagementTableNavigation = new Message(getName(MessageEnum.NavigationMessage));
    LeadManagementTableNavigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Applications"
    );
    LeadManagementTableNavigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(LeadManagementTableNavigation);
  };

  getEmployees = async () => {
    const header = {
    token: this.state.userToken,
      "Content-Type": configJSON.employeesApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employeesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.employeesApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountries = async () => {
    const header = {
    token: this.state.userToken,
      "Content-Type": configJSON.countriesApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.countriesApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryRelatedData = async (countryName:string) => {
    const header = {
      "Content-Type": configJSON.countriesRelatedDataApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countriesRelatedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.countriesRelatedDataApiEndPoint.replace("{authToken}",this.state.userToken).replace("{countryName}", countryName)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.countriesRelatedDataApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCreatedBy = async () => {
    const header = {
    token: this.state.userToken,
      "Content-Type": configJSON.createdByDataApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createdByApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createdByDataApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createdByDataApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ifStatement = (condition:boolean,value:string,error:string) => {
    if(condition) {
      this.setState((prevState)=>({...prevState,[value]:error}))
    }else {
      this.setState((prevState)=>({...prevState,[value]:""}))
    }
  }

  onEmployeeChange = (event: SelectEventType) => {
    this.setState({ employeeValue: event.target.value });
  };

  handleClose = () => {
    this.setState({successPopUp:false})
    this.onLeadManagementTable()
  }

  handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ countryValue: event.target.value });
    this.ifStatement(event.target.value === "none","countryErrorTxt","Country name is required.")
    this.getCountryRelatedData(event.target.value as string)
  };

  handleCourseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ preferCourseValue: event.target.value as string });
    this.ifStatement(event.target.value === "none","preferCourseValueErrorTxt","Preferred course is required.")
  };

  setCourses = (college:CollegeDataType) => {
    this.setState({coursesData:college.attributes.courses,universityId:college.id})
  }

  setIntakeSession = (course:CoursesDataType) => {
    const tempSessionData = course.sessions.map((session)=>{return {id:session.id,sessionName:session.months.join("-")}})
   this.setState({inTakeData:tempSessionData,courseId:course.id})
  }

  handleCollegeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ preferCollegeValue: event.target.value as string });
    this.ifStatement(event.target.value === "none","preferCollegeValueErrorTxt","Preferred college is required.")
  };

  handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ intakeYearTxtValue: event.target.value as string });
    this.ifStatement(event.target.value === "none","intakeYearTxtValueErrorTxt","Year is required.")
  };

  onRemarkChange = (event: { target: { value: string; }; }) => {
    const inputValue = event.target.value;
    this.setState({ remarkTxtValue: inputValue });
    this.ifStatement(inputValue === "","remarkTxtValueErrorTxt","Remark is required.")
  };

  setSessionId = (session:{id:string;sessionName:string}) => {
    this.setState({sessionId:session.id})
  }

  handleCreatedChange = (event: { target: { value: unknown; }; }) => {
    const inputValue = event.target.value;
    this.setState({ createdValue: inputValue });
    this.ifStatement(inputValue === "","createdValueErrorTxt","Created by is required.")
  };

  setStudentId = (student:EmployeeDataType) => {
    this.setState({ studentId: student.id, studentIDGen: student.attributes.student_id });
  }

  onInTakeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ inTakeValue: event.target.value as string });
    this.ifStatement(event.target.value === "none","inTakeValueErrorTxt","Intake is required.")
  };

  onAddApllication = () => {
    const validationChecks = [
      { value: this.state.employeeValue === "none", errorState: "employeeValueError", errorTxtState: "employeeValueErrorTxt", errorMessage: "Student name is required." },
      { value: this.state.countryValue === "none", errorState: "countryError", errorTxtState: "countryErrorTxt", errorMessage: "Country name is required." },
      { value: this.state.preferCollegeValue === "none", errorState: "preferCollegeValueError", errorTxtState: "preferCollegeValueErrorTxt", errorMessage: "Preferred college is required." },
      { value: this.state.preferCourseValue === "none", errorState: "preferCourseValueError", errorTxtState: "preferCourseValueErrorTxt", errorMessage: "Preferred course is required." },
      ];
this.setState(prevState => {
  const updatedState: { [key: string]: boolean | string } = {};
  validationChecks.forEach(({ value, errorState, errorTxtState, errorMessage }) => {
    updatedState[errorState] = value;
    updatedState[errorTxtState] = value ? errorMessage : "";
  });
  return { ...prevState, ...updatedState };
})
const hasError = validationChecks.some(check => check.value);
if(!hasError){
  this.ApplyNowButtonOfShortlistTab()
}
  };

  navigationToAnyPage = (pageName:string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  postApiCall = async () => {
    const header = {
  token: this.state.userToken,
  contentType: configJSON.addApplicationContentType,
};
const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
const tempData = {
  student_application:{
    student_id:this.state.studentId,
    my_preferred:"false",
    expert_advise:"false",
    destination:this.state.countryValue,
    university_id:this.state.universityId,
    prefer_course_id:this.state.courseId,
    session_id:this.state.sessionId,
    intake_year: this.state.intakeYearTxtValue,
    remarks:this.state.remarkTxtValue,
    created_by:this.state.createdValue
  },
}

const formData = new FormData();

Object.keys(tempData.student_application).forEach((student, index) => {
  formData.append(`[student_application][${student}]`, Object.values(tempData.student_application)[index] as string);
});

this.addApplicationApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.addApplicationEndPoint
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.addApplicationApimethod
);
runEngine.sendMessage(requestMessage.id, requestMessage);
};


ApplyNowButtonOfShortlistTab = async () => {
  const formData = new FormData();
  formData.append("generated_student_id", this.state.studentIDGen)
  formData.append("student_id", this.state.studentId)
  formData.append("course_id", this.state.courseId)
  this.ApplyNowShortlistButtonApiCallId = await this.apiCall({
    method: "POST",
    endPoint: `/bx_block_leadmanagement/forms/apply_now`,
    type: "formData",
    body: formData
  });
}

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = this.state.userToken
    const headerValue = {"Content-Type": contentType,token: token};
    const requestMessageLead = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessageLead.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessageLead.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerValue)
    );

    requestMessageLead.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type
      !== "formData"
      ? requestMessageLead.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageLead.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageLead.id, requestMessageLead);
    return requestMessageLead.messageId;
  };

  apiSucessCall = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || responseJson.message;
  };

  getProfileSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({
      userDetails: responseJson.data
    });
  };

  profileApi = (responseJson: ValidResponseType & UserDetailsResponse, apiRequestCallId: string) => {
    if (this.isValidResponse(responseJson)) {
      this.apiSucessCall(apiRequestCallId, responseJson);
    }
  };

  getProfileAccount = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
};
  // Customizable Area End
}