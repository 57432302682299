// Customizable Area Start
import {
  getStorageData,
  setStorageData,
} from "../../../../packages/framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const navigation = require("react-navigation");
import {
  Engineering,
  Law,
  UniversityLogo1,
  UniversityLogo2,
  UniversityLogo3,
  UniversityLogo4,
  UniversityLogo5,
  UniversityLogo6,
  UniversityLogo7,
  ImageOne,
  Science,
  Arts,
  Buisness,
  EnglishLanguage,
  Medicine,
  Economics,
  CanadaFlag,
  CountryFlag,
  USAFlag,
  AustraliaFlag,
  IreLandFlag,
  ChartLogo,
  LaptopLogo,
  ThreeLineLogo,
  TimerLogo,
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

interface UnivercityLogo {
  id: number;
  logoImg: string;
}
interface ExplorePopularFieldOfstudy {
  id: number;
  imageURL: string;
  title: string;
}

interface FlagData {
  id: number;
  title: string;
  logoImg: string;
}

interface StepsData {
  stepTitle: string;
  id: number;
  stepDescription: string;
}
interface ContentDataType {
  id: string;
  type: string;
  attributes: {
    description: string;
    place: string;
    content_type: string;
    title: string;
    url: { url: string };
    service: { id: number; service: { id: number; service: string } };
    university_country_id: number;
    country_name: string;
  };
}

interface FormattedContentDataType {
  id: string;
  type: string;
  attributes: {
    description: string;
    place: string;
    content_type: string;
    title: string;
    url: { url: string };
    service: { id: number; service: string } | null;
    countryName: string;
    countryId: number;
  };
}

interface ServiceDataType {
  id: string;
  type: string;
  attributes: {
    title: string;
    service_type: string;
  };
}

interface CountryDataType {
  type: string;
  id: string;
  attributes: {
    country_name: string;
    id: number;
  };
}

type contentTypeMerge =
  | FormattedContentDataType
  | FormattedContentDataType[]
  | undefined;
interface ApplyBoardSection {
  id: number;
  title: string;
  subTitle: string;
  imageURL: string;
}
interface AboutUsContent {
  data: AboutUsData[];
  meta: Meta;
}

interface AboutUsData {
  id: string;
  type: string;
  attributes: Attributes;
}
interface CountryName {
  id: number;
  countryName: string;
}

interface StudentDataContent {
  id: string;
  type: string;
  attributes: Attributes2;
}

interface Attributes2 {
  id: number;
  place: string;
  updated_at: string;
  content_type: string;
  title: string;
  description: string;
  created_at: string;
  university_country_id: number | null;
  country_name: string | null;
  students_enrolled: string;
  applications_proceeded: number;
  url: UrlData;
  service: ServiceData;
}

interface ServiceData {
  id: number;
  service: ServiceDetails | null;
}

interface ServiceDetails {
  id: number;
  service_type: string;
  updated_at: string;
  created_at: string;
}

interface UrlData {
  url: string;
}

interface Attributes {
  banner_title: string;
  banner_description: string;
  banner: string;
  our_services: Service[];
  page_body: string;
  our_impact: string;
}

interface Service {
  title: string;
  description: string;
}

interface Meta {
  message: string;
}

interface InstitutionResponse {
  data: InstitutionData[];
  meta: MetaData;
}

interface InstitutionData {
  id: string;
  type: string;
  attributes: InstitutionAttributes;
}

interface InstitutionAttributes {
  banner_title: string;
  institution_benefits: InstitutionBenefit[];
  banner_description: string;
  banner: string;
}

interface InstitutionBenefit {
  title: string;
  benefits: string;
}

interface MetaData {
  message: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  UserRole: string;
  explorePopularFieldOfstudy: ExplorePopularFieldOfstudy[];
  univercityLogo: UnivercityLogo[];
  showSchool: string;
  selectedSchoolTab: string;
  stepData: StepsData[];
  toggleDrawer: boolean;
  serviceData: contentTypeMerge;
  allServices: { id: string; serviceName: string }[];
  applyBoardSection: ApplyBoardSection[];
  flagData: FlagData[];
  activeId: number | null;
  showAllUniversitylogo: boolean;
  allCountryData: contentTypeMerge;
  countryName: CountryName[];
  allCountryName: CountryDataType[];
  menuOptions: { id: number; title: string }[];
  contentData: FormattedContentDataType[];
  bannerData: {
    Agent: FormattedContentDataType | undefined;
    school: FormattedContentDataType | undefined;
  } | null;
  middleCardData: {
    Agent: contentTypeMerge;
    student: contentTypeMerge;
  };
  belowCardData: {
    Agent: contentTypeMerge;
    student: contentTypeMerge;
  };
  selectedTab: string;
  aboutUsData: AboutUsData[];
  studentData: StudentDataContent[];
  activeTab: string;
  institutionsData: InstitutionData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutUsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contentManagementCallApiId: string = "";
  countryCallApiId: string = "";
  getAboutUsDataApiCallId: string = "";
  getStudentDataApiCallId: string = "";
  servicesCallApiId: string = "";

  getInstitutionsCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.formatComponentData = this.formatComponentData.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      contentData: [],
      serviceData: [],
      showSchool: "",
      activeId: null,
      allServices: [],
      showAllUniversitylogo: false,
      belowCardData: {
        student: [],
        Agent: [],
      },
      allCountryName: [],
      toggleDrawer: false,
      allCountryData: [],
      selectedTab: "",
      selectedSchoolTab: "students",
      bannerData: null,
      middleCardData: { Agent: [], student: [] },
      stepData: [
        {
          id: 1,
          stepTitle: "Step 1",
          stepDescription: "check Your Elibility",
        },
        {
          id: 2,
          stepTitle: "Step 2",
          stepDescription: "Submit Your Application",
        },
        {
          id: 3,
          stepTitle: "Step 3",
          stepDescription: "Got offer letter Acceptance",
        },
        {
          id: 4,
          stepTitle: "Step 4",
          stepDescription: "Apply For Your Visa",
        },
      ],

      flagData: [
        {
          id: 1,
          logoImg: CanadaFlag,
          title: "Study in Canada",
        },
        {
          id: 2,
          logoImg: CountryFlag,
          title: "Study in UK",
        },
        {
          id: 3,
          logoImg: USAFlag,
          title: "Study in USA",
        },
        {
          id: 4,
          logoImg: AustraliaFlag,
          title: "Study in Australia",
        },
        {
          id: 5,
          logoImg: IreLandFlag,
          title: "Study in Ireland",
        },
      ],
      explorePopularFieldOfstudy: [
        { id: 1, imageURL: Engineering, title: "Engineering" },
        { id: 2, imageURL: Law, title: "Law" },
        { id: 3, imageURL: Science, title: "Science" },
        { id: 4, imageURL: Arts, title: "Arts" },
        { id: 5, imageURL: Buisness, title: "Buisness" },
        { id: 6, imageURL: EnglishLanguage, title: "English Language" },
        { id: 7, imageURL: Medicine, title: "Medicine" },
        { id: 8, imageURL: Economics, title: "Economics" },
      ],      
      menuOptions: [{id: 1,title: "About us",},],
      univercityLogo: [
        { id: 1, logoImg: UniversityLogo1 },
        { id: 2, logoImg: UniversityLogo2 },
        { id: 3, logoImg: UniversityLogo3 },
        { id: 4, logoImg: UniversityLogo4 },
        { id: 5, logoImg: UniversityLogo5 },
        { id: 6, logoImg: UniversityLogo6 },
        { id: 7, logoImg: UniversityLogo7 },
      ],
      
      applyBoardSection: [
        { id: 1, imageURL: ImageOne, title: "RBC International Student", subTitle: "Strengthen and streamline your study permit application with a GIC from RBC, Canada’s largest bank." },
        { id: 2, imageURL: ImageOne, title: "Students Loans Services", subTitle: "Strengthen and streamline your study permit application with a GIC from RBC, Canada’s largest bank." },
        { id: 3, imageURL: ImageOne, title: "Tuition Payments Made Easy", subTitle: "Strengthen and streamline your study permit application with a GIC from RBC" },
      ],      
      countryName: [
        { id: 1, countryName: "United Kindom" },
        { id: 2, countryName: "Canada" },
        { id: 3, countryName: "United States" },
        { id: 4, countryName: "Australia" },
        { id: 5, countryName: "Ireland" },
      ],
      
      activeTab: "aboutUs",
      institutionsData: [],
      aboutUsData: [],
      studentData: [],
      UserRole: "",
    };
    // Customizable Area End
  }

  // Customizable Area Start
  onPartnerSchoolClick = () => {};
  async componentDidMount() {
    this.getContentManagement();
    this.getStudentContent();
    this.getServices();
    this.getCountries();
    this.getAboutUsContent();
    this.getInstitutionsContent();
    const getLandingActivePage = await getStorageData("LandingPageActive");
    this.renderScreenInLandingPage(getLandingActivePage);
    const UserRole = await getStorageData("role");
    this.setState({
      UserRole: UserRole,
    });
    const activeHeaderTab = await getStorageData("activeHeaderTab");
    this.setState({ showSchool: activeHeaderTab });
  }

  filterData = (
    returnType: string,
    data: FormattedContentDataType[],
    place: string,
    contentType: string
  ) => {
    if (returnType === "object") {
      return data.find(
        (format) =>
          format.attributes.place === place &&
          format.attributes.content_type === contentType
      );
    }
    if (returnType === "array") {
      return data.filter(
        (format) =>
          format.attributes.place === place &&
          format.attributes.content_type === contentType
      );
    }
  };

  filterBelowCardData = (id: number) => {
    if (this.state.activeId === id) {
      this.setState({
        belowCardData: {
          ...this.state.belowCardData,
          Agent: this.state.allCountryData,
        },
        activeId: null,
      });
    } else {
      const filteredData = Array.isArray(this.state.allCountryData)
        ? this.state.allCountryData.filter(
            (below) => below.attributes.countryId === id
          )
        : [];
      this.setState({
        belowCardData: { ...this.state.belowCardData, Agent: filteredData },
        activeId: id,
      });
    }
  };

  formatComponentData = (data: ContentDataType[]) => {
    const formatData = data.map((value) => {
      return {
        id: value.id,
        type: value.type,
        attributes: {
          title: value.attributes.title,
          description: value.attributes.description,
          service: value.attributes.service.service,
          place: value.attributes.place,
          url: value.attributes.url,
          content_type: value.attributes.content_type,
          countryId: value.attributes.university_country_id,
          countryName: value.attributes.country_name,
        },
      };
    });

    const schoolBannerData = this.filterData(
      "object",
      formatData,
      "Banner",
      "student"
    );
    const recruiterBannerData = this.filterData(
      "object",
      formatData,
      "Banner",
      "Agent"
    );

    const schoolBelowData = this.filterData(
      "array",
      formatData,
      "Below",
      "student"
    );
    const recruiterBelowData = this.filterData(
      "array",
      formatData,
      "Below",
      "Agent"
    );

    const schoolCardData = this.filterData(
      "array",
      formatData,
      "Middle (Service)",
      "student"
    );
    const recruiterCardData = this.filterData(
      "array",
      formatData,
      "Middle (Service)",
      "Agent"
    );

    const studentBannerData = this.filterData(
      "object",
      formatData,
      "Banner",
      "Student"
    );

    const studentBelowData = this.filterData(
      "array",
      formatData,
      "Below (University)",
      "Student"
    );

    const studentCardData = this.filterData(
      "array",
      formatData,
      "Middle (Service)",
      "Student"
    );

    this.setState({
      contentData: formatData,
      middleCardData: { ...this.state.middleCardData, student: schoolCardData },
      belowCardData: {
        student: schoolBelowData,
        Agent: recruiterBelowData,
      },
      allCountryData: recruiterBelowData,
      serviceData: recruiterCardData,
    });
    this.tabSelected(this.state.allServices[0]?.id);
    if (
      !Array.isArray(schoolBannerData) &&
      !Array.isArray(recruiterBannerData) &&
      !Array.isArray(studentBannerData)
    ) {
      this.setState({
        bannerData: {
          school: schoolBannerData,
          Agent: recruiterBannerData,
        },
      });
    }
  };
  formatServicesData = (data: ServiceDataType[]) => {
    const formatData = data.map((value) => {
      return {
        id: value.id,
        serviceName: value.attributes.service_type,
      };
    });
    this.setState({ allServices: formatData });
    if (formatData.length > 0) {
      this.setState({ selectedTab: formatData[0].id });
    }
  };

  showUniversityLogo = () => {
    this.setState({ showAllUniversitylogo: !this.state.showAllUniversitylogo });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiFunctionsMap = {
        [this.contentManagementCallApiId]: this.formatComponentData,
        [this.servicesCallApiId]: this.formatServicesData,
        [this.getStudentDataApiCallId]: this.setStudentDatass,
        [this.countryCallApiId]: this.setAllCountries,
        [this.getAboutUsDataApiCallId]: this.setAboutUsContent,
        [this.getInstitutionsCallId]: this.setInstitutionsContent,
      };

      const apiFunction = apiFunctionsMap[apiRequestCallId];

      if (apiFunction && responseJson && responseJson?.data?.length > 0) {
        apiFunction(responseJson.data);
      }
    }
  }

  setAllCountries = (allCountries: CountryDataType[]) => {
    this.setState({ allCountryName: allCountries });
  };

  getContentManagement = async () => {
    const header = 
    {"Content-Type": configJSON.contentManagementApiContentType,};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contentManagementCallApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.contentManagementApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contentManagementApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServices = async () => {
    const header = {"Content-Type": configJSON.serviceApiContentType,};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.servicesCallApiId = 
    requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.serviceApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentContent = async () => {
    const header = {"Content-Type": configJSON.serviceApiContentType,};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStudentDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.contentManagementApiStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.serviceApiMethod
    );
        runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAboutUsContent = async () => {
    const header = {"Content-Type": configJSON.serviceApiContentType,};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAboutUsDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.aboutusApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountries = async () => {
    const header = {"Content-Type": configJSON.serviceApiContentType,};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCallApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.countryApiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInstitutionsContent = async () => {
    const header = {"Content-Type": configJSON.serviceApiContentType,};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInstitutionsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getInstitutionsEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToLoginPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"EmailAccountLoginBlock");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigateToRegistration = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"EmailAccountRegistration");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer,
    });
  };

  tabSelected = (selectedTab: string) => {
    this.setState({ selectedTab: selectedTab });
    const getFilterData =
      this.state.serviceData && Array.isArray(this.state.serviceData)
        ? this.state.serviceData.filter(
            (service) =>
              service.attributes.service?.id && Number(service.attributes.service?.id) === Number(selectedTab)
          )
        : [];
    this.setState(
        {
      middleCardData: { ...this.state.middleCardData,
        Agent: getFilterData },
    }
);
  };

  onHomeClick = (pageName: string) => {this.renderScreenInLandingPage(pageName);
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage),"LandingPage");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  schoolPageTabSelected = (selectedTab: string) => {
    this.setState(
        { selectedSchoolTab: selectedTab }
    );
  };
  renderSchool = () => {
    setStorageData("LandingPageActive", "school");
    this.setState(() => ({showSchool: "school",toggleDrawer: false,}));
  };
  navigateToAnyPage = (navigateTo: string) => {this.props.navigation.navigate(navigateTo);};

  renderButtonClick = (value: string) => {
    // Update the state to track the selected tab
    this.setState(
        { showSchool:value }
    );
    switch (value) {
      case "contact":this.props.navigation.navigate("Contactus", { showSchool: value });
        break;
      case "students":this.props.navigation.navigate("StudentPage", { showSchool: value });
        break;
      case "aboutUs":this.props.navigation.navigate("AboutPage", { showSchool: value });
        break;
      case "institutions":this.props.navigation.navigate("InstitutePage", { showSchool: value });
        break;
      case "agent":this.props.navigation.navigate("LandingPage", { showSchool: value });
        break;
      default:
        break;
    }
  };

  renderRecruitmentPartner = () => {setStorageData("LandingPageActive", "RecruitmentPartner");
    this.setState(() => ({showSchool: "RecruitmentPartner",toggleDrawer: false,}));
  };

  setAboutUsContent = (responseJson: AboutUsData[]) => {
    this.setState({aboutUsData: responseJson,});
  };

  renderScreenInLandingPage = (value: string) => {
    if (value) {
      this.setState(() => ({showSchool: value,}));
    }
  };

  onSignUpClick?: () => void;
  onLoginClick?: () => void;
  clickOnLogin = () => {
    if (this.onLoginClick) {this.onLoginClick();
      setStorageData("LandingPageActive", "notActive");
    }
  };

  clickOnRegister = () => {
    if (this.onSignUpClick) {this.onSignUpClick();
      setStorageData("LandingPageActive", "notActive");
    }
  };

  setInstitutionsContent = (responseJson: InstitutionData[]) => {
    this.setState(
        { institutionsData: responseJson }
    );
  };
  setStudentDatass = (responseJson: StudentDataContent[]) => {
    this.setState(
        {
      studentData: responseJson,
    }
);
  };
  // Customizable Area End
}
