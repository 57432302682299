import React from "react";
// Customizable Area Start
export const configJSON = require("./config");
import {
  IntegrityLogo,
  ExcellenceLogo,
  InnovationLogo,
  CollaborationLogo,
  ServiceLogo, 
} from "./assets";
import { createTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  styled,
  Paper,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import AboutUsPageController, { Props } from "./AboutUsPageController.web";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#BB393D",
    },
    secondary: {
      main: "#059669",
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
});

// Customizable Area End
export default class AboutUsPage extends  AboutUsPageController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start

  renderAboutUsBanner = () => {
    return (
     <AboutUsBanner>
        <BannerUpDiv component={"div"}><img data-test-id="aboutUsbannerImg" className="bannerImg" src={this.state?.aboutUsData[0]?.attributes?.banner||""} alt="loading..." /></BannerUpDiv>
        <BannerTxtDiv>
          <Box className="bannerTxtDivInner">
              <BannerHeader>{this.state?.aboutUsData[0]?.attributes?.banner_title||""}</BannerHeader>
               <BannerSubHeader dangerouslySetInnerHTML={{__html:this.state?.aboutUsData[0]?.attributes?.banner_description||""}}> 
              </BannerSubHeader>
              </Box>
          </BannerTxtDiv>
      </AboutUsBanner> 
    );
  };

  renderAboutUsText = () => {
    return <AboutUsTextBox dangerouslySetInnerHTML={{__html:this.state?.aboutUsData[0]?.attributes?.page_body||""}}>
     
    </AboutUsTextBox>
  }

  renderOurValues = () =>{
    const boxesData = [{
      img:IntegrityLogo,
      heading:"Integrity",
      desc:"We uphold the highest standards of honesty and transparency in all our interactions."
    },
    {
      img:ExcellenceLogo,
      heading:"Excellence",
      desc:"We strive for excellence in everything we do, ensuring quality and value in our services."
    },
    {
      img:InnovationLogo,
      heading:"Innovation",
      desc:"We embrace change and continuously seek innovative solutions to meet the evolving needs of our clients."
    },
    {
      img:CollaborationLogo,
      heading:"Collaboration",
      desc:"We believe in the power of collaboration, working closely with students, parents, and educational institutions to achieve shared goals."
    },
  ]

  return (
    <OurValuesBox>
      <Box className="headingBox">
        <Typography className="textHeading">{configJSON.ourValuesTxt}</Typography>
        <Typography className="textDesc">{configJSON.ourValuesDesc}</Typography>
      </Box>
      <Box className="itemsBox">
    {boxesData?.map((boxData,currIndex)=><Box component={Paper} className="itemBox" key={currIndex}>
      <img className="boxImg" src={boxData.img}/>
      <Typography className="boxHeading">{boxData.heading}</Typography>
      <Typography className="boxDesc">{boxData.desc}</Typography>
    </Box>)}
    </Box>
    </OurValuesBox>
  )


  }

  renderOurServices = () =>{
    const servicesData = this.state?.aboutUsData[0]?.attributes?.our_services||[]
    return <OurServicesBox>
      <Box className="leftServiesBox">
      <Typography className="textHeading">{configJSON.ourServices}</Typography>

      </Box>
      <Box className="rightServicesBox">
        {servicesData.map((service,currIndex)=><Box className="serviceItem">
          <Box className="headerBox">
         <img src={ServiceLogo} alt={`img${currIndex}`}/>
         <Typography className="serviceHead">{service?.title}</Typography>
         </Box>
         <Box className="serviceDesc" dangerouslySetInnerHTML={{__html:service?.description||""}}></Box>
          </Box>)}
      </Box>
    </OurServicesBox>
  }

  renderOurImpact = () =>{

    return <OurImpactBox>
      <Box className="rightImpactBox">
        <Typography className="impactHeader">{configJSON.ourImpact}</Typography>
        <Box className="impactDesc" dangerouslySetInnerHTML={{__html:this?.state?.aboutUsData[0]?.attributes.our_impact||""}}></Box>
      </Box>
      <Box className="leftImpactBox">
        <button className="contactUsBtn" onClick={()=>this.navigateToAnyPage("Contactus")} data-test-id="navigateContactId">{configJSON.contactUsTxt}</button>
      </Box>

    </OurImpactBox>
  }

  renderAboutUs = () =>{
    return <>
   {this.renderAboutUsBanner()} 
   {this.renderAboutUsText()}
   {this.renderOurValues()}
   {this.renderOurServices()}
   {this.renderOurImpact()}
    </>
  }

  // Customizable Area End

    render() {
        return (
      // Customizable Area Start
      <>
        <AppHeader
        navigation={this.props.navigation} isLogin={true}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          showScool={this.state.showSchool}
        />
        {this.renderAboutUs()}
         <Footer renderButtonClick={this.renderButtonClick} onAnyPageNavigate={this.navigateToAnyPage}/>      
      </>
      // Customizable Area End
        );
    }
}

// Customizable Area Start

const BannerUpDiv = styled(Box)({
  height: "640px",
  fontFamily: "Plus Jakarta Sans",
  width: "100%",
  position: "absolute",
  opacity: 0.8,
  borderRadius: "8px"
});

const BannerTxtDiv = styled(Box)({
  position: "relative",
  width: "100%",
  height: "640px",
  top: 0,
  left: 0,
  fontFamily: "Plus Jakarta Sans",
  background: "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1C4A39 100%)",
});

const BannerHeader = styled(Typography)({
  fontWeight: "bold",
  color: "white",
  paddingLeft: "10vw",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "24px", 
    width: "75vw",
    lineHeight: "28px",

  },
  "@media (min-width:720px)": {
    fontSize: "48px",
     width: "531px",
    lineHeight: "56px",
  }
})
const BannerSubHeader = styled(Typography)({
  color: "white",
  fontFamily: "Plus Jakarta Sans",
  paddingLeft: "10vw",
  marginBottom: '34px',
  "@media (max-width:960px)": {
    fontSize: "0.8rem", 
    width: "75vw",
    lineHeight: "12px",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem", 
    width: "531px",
    lineHeight: "24px",
  }
})

const AboutUsBanner = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 645,
  fontFamily: "Plus Jakarta Sans",
  "@media (min-width:960px)": {
    height:600,
  },
  "@media (min-width:600px)": {
    height: 300,
  },
  "& .bannerImg": {
    height: "100%",
    width: "100%"
  },
  "& .bannerTxtDivInner": {
    width: "100%",
     height: "100%", 
     display: "flex", 
     flexDirection: "column", 
     justifyContent: "center",
      gap: "4px"
  }
});

const AboutUsTextBox = styled(Box)({
  "& p":{
    maxWidth:"800px",
    textAlign:"center",
    fontSize:"30px",
    margin:"100px auto"
  }

})

const OurValuesBox = styled(Box)({
  padding:"5rem 0",
  background: "#F7F7F7",
  width:"100%",
  "& .headingBox":{
    textAlign:"center",
    maxWidth:"800px",
    margin:"40px auto",
  },
  "& .textHeading":{
    fontSize:"35px",
    fontWeight:700,
    marginBottom:"10px",
  },

  "& .itemsBox":{
  display:"flex",
  justifyContent:"space-evenly",
  flexWrap:"wrap",
  "& .itemBox":{
    width:"200px",
    flexDirection:"column",
    padding:"20px 30px",
    display:"flex",
    gap:"10px",
    alignItems:"center",
    justifyContent:"center",
    marginBottom:"20px",
  },
  "& img":{
    width:"110px",
    height:"110px"
  },
  "& .boxHeading":{
    textAlign:"center",
    fontWeight:"700",
    fontSize:"23px",
  },
  "& .boxDesc":{
    textAlign:"center",
    fontSize:"16px",
  }
  }
})

const OurServicesBox = styled(Box)({
  padding:"5rem 0",
  display:"flex",
  "& .leftServiesBox":{
    width:"50%",
  },
  "& .rightServicesBox":{
    width:"50%",
    padding:"0 50px"
  },
  "& .textHeading":{
    fontWeight:700,
    fontSize:"35px",
    paddingLeft:"120px"
  },
  "& .serviceItem":{
    maxWidth:"560px",
    marginBottom:"30px",
  },
  "& .headerBox":{
    display:"flex",
    alignItems:"center",
    gap:"10px"
  },
  "& .serviceHead":{
    fontWeight:"700",
    fontSize:"23px",
  },
  "& .serviceDesc":{
    fontSize:"16px",
    marginLeft:"40px"
  },
})

const OurImpactBox = styled(Box)({
  background:"#F7F7F7",
  padding:"5rem 0",
  width:"100%",
  display:"flex",
  justifyContent:"space-between",
  "& .rightImpactBox":{
    width:"50%",
    paddingLeft:"120px",
  },
  "& .impactHeader":{
    fontSize:"35px",
    fontWeight:700,
  },
  "& .leftImpactBox":{
    paddingRight:"120px",
  },
  "& .contactUsBtn":{
    background:"#33D499",
    color:"white",
    padding:"15px",
    border:"none",
    outline:"none",
    fontWeight:700,
    fontSize:"16px",
    margin:"0 auto",
    borderRadius:"10px",
  }

})
// Customizable Area End