import React from "react";
// Customizable Area Start

import LandingPageController, { Props } from "./LandingPageController.web";
export const configJSON = require("./config");
import {
  featureBg
} from "./assets";
import { createTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SchoolIcon from '@mui/icons-material/School';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#BB393D",
    },
    secondary: {
      main: "#059669",
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
});






// Customizable Area End
export default class StudentPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start

  StudentBanner = () => {
    // Filter to find the banner data where place is "Banner"
    const bannerData = this.state.studentData.find(
      item => item.attributes.place === "Banner"
    );
  
    return (
      <Banner>  
        <BannerUpDiv component={"div"}>
          <img
            width={"100%"}
            height={"100%"}
            src={bannerData?.attributes?.url?.url}
            alt="loading..."
          />
        </BannerUpDiv>
  
        <BannerTxtDiv>
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "4px"
            }}
          >
            <BannerHeader>{bannerData?.attributes?.title}</BannerHeader>
            <BannerSubHeader>{bannerData?.attributes?.description}</BannerSubHeader>
          </Box>
        </BannerTxtDiv>
      </Banner>
    );
  };
  

  renderStudentHeadingtext=()=>{
    return(
      <Box sx={webStyles.getStartedBoardApplyMainBox} >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{gap:"41px"}}
        >
          <Grid item xs={12} container style={{gap:"21px"}}>
            <CenterTypography
              >
              Get Started With Vision International School
            </CenterTypography>
            <CenterTypographyTwo
            >
Explore exclusive program to help you save time and money.With Vision International Educational Consultants, you're never far from a helping hand.            </CenterTypographyTwo>
          </Grid>

        </Grid>
        </Container>
        </Box>  
    )
  }

  renderStudentHeadingtext2=()=>{
    return(
      <Box sx={webStyles.getStartedBoardApplyMainBox} >
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{gap:"41px"}}
        >
          <Grid item xs={12} container style={{gap:"21px",padding:'0 100px', textAlign:'center'}}>
            <CenterTypography
              >
              We are Invested in Caring for You and the Ecosystem of International Education
            </CenterTypography>
          </Grid>

        </Grid>
        </Container>
        </Box>  
    )
  }

  StatsSection = () => {
    const statsData = [
      { value: this.state.studentData[0]?.attributes?.students_enrolled, label: "Students enrolled" },
      { value: "+12,000", label: "Applications proceeded" },
      { value: "+560", label: "Institutions cooperated" },
      { value: "+30,000", label: "Students graduated" },
    ];
  
    return (
      <Box
        sx={{
          borderRadius: '8px',
          padding: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} style={{justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
          <StatsFlow className="stts-barnew" style={{justifyContent:'center',display:'flex',marginBottom:'30px'}}>
          {statsData.map((stat, index) => (
            <Grid style={{marginRight:'45px'}}>
              <Typography variant="h5" style={{ color: '#059669', fontWeight: '700' , fontSize:'36px' }}>
                {stat.value}
              </Typography>
              <Typography variant="body2" style={{ color: '#000',fontSize:'18px' }}>
                {stat.label}
              </Typography>
            </Grid>
          ))}
          </StatsFlow>
          <Button style={{border:"1px solid white",marginBottom:'55px',borderRadius:"8px",gap:"8px",background:'#BB393D',marginTop:'25px'}}>
                    <AcheiveGoalButton variant="button" onClick={()=>this.navigateToAnyPage("Contactus")}>
                      {configJSON.btnLabelGetPersionalizedStudyAbrooadOption}
                    </AcheiveGoalButton>
                  </Button>
        </Grid>
        
      </Box>
    );
  };

  renderGetStartedApplyBoardStudent = () => {
    const MiddleData = this.state.studentData.find(
      item => item.attributes.place === "Middle (Service)"
    );
    return (
      <Box sx={webStyles.getStartedBoardApplyMainBox} >
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{gap:"41px"}}
          > 
            <Grid
                item
                xs={12}
                style={{display:"flex"}}
                justifyContent="center"
                alignItems="center"
              >
              </Grid>
              
            {MiddleData ? (
              <>
              <Grid item xs={12} md={4} sm={6} lg={3} style={{display:"flex"}} justifyContent="center">
                <ImageCardNew>
                
                <UnderImageBox width="full" height="10.813rem">
                        <img src={MiddleData.attributes.url?.url} width="100%" height="100%" />
                        <GradiantColorBox width="100%" height="100%" />
                      </UnderImageBox>
                      <Box style={{display:"flex",height:"50%",flexDirection:"column",justifyContent:"space-between",alignItems:"start"}}>
                  <CardContent>
                    <ImageBoxTextHeader>{MiddleData.attributes.title}</ImageBoxTextHeader>
                    <ImageBoxTextDescription>{MiddleData.attributes.description}</ImageBoxTextDescription>   
                  </CardContent>
                  
                  </Box>
                </ImageCardNew>
              </Grid>
             </>
            ): (
              <CenterTypographyTwo> There is no Data...! 
              </CenterTypographyTwo>
            )}
          </Grid>
        </Container>
      </Box>
    );
  };

  renderPlatformSchool = () => {
    const features = [
      { title: "Find Programs Faster", description: "Stellar filecoin harmony amp USD. Kusama avewave USD quant crypto fantom algorand avewave serum." },
      { title: "One Easy Application", description: "Stellar filecoin harmony amp USD. Kusama avewave USD quant crypto fantom algorand avewave serum." },
      { title: "Central Platform", description: "Stellar filecoin harmony amp USD. Kusama avewave USD quant crypto fantom algorand avewave serum." },
      { title: "Data Driven Insights", description: "Stellar filecoin harmony amp USD. Kusama avewave USD quant crypto fantom algorand avewave serum." }
    ];
  
    return (
      <FeatureBox>
        <Box className="feature-list-box">
        <Grid spacing={4} className="feature-list-container" style={{display:'flex'}}>
          {/* Left side - Title */}
          <Grid item xs={12} md={6} className="feature-list-left">
            <img src={featureBg} />
          <Typography 
              variant="h4" 
              component="h2" 
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              An Easy-to-Use Platform that Connects You to the Right School
            </Typography>

          </Grid>
  
          {/* Right side - Features list */}
          <Grid item xs={12} md={4} className="feature-list-right">
            <Grid direction="column" spacing={2}>
              {features.map((feature, index) => (
                <Grid item key={index}>
                  <Card style={{ display: 'flex', alignItems: 'center', borderRadius: 2, boxShadow:'none !important'}}>
                    <SchoolIcon sx={{ color: '#f28c8c', fontSize: 40, mr: 2 }} /> {/* Icon styling */}
                    <CardContent style={{ padding: 0 }}>
                      <Typography style={{fontWeight:'bold'}}>
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      </FeatureBox>
    );
  };
  renderStudentPage = () =>{
    return <>
      {this.StudentBanner()}
      {this.renderStudentHeadingtext()}
      {this.renderGetStartedApplyBoardStudent()}
      {this.renderPlatformSchool()}
      {this.renderStudentHeadingtext2()}
      {this.StatsSection()};
      </>
  }
  // Customizable Area End

    render() {
        return (
      // Customizable Area Start
      <>
        <AppHeader isLogin={true}
          navigation={this.props.navigation}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          showScool={this.state.showSchool}
        />
        {this.renderStudentPage()}
         <Footer renderButtonClick={this.renderButtonClick} onAnyPageNavigate={this.navigateToAnyPage}/>      
      </>
      // Customizable Area End
        );
    }
}

// Customizable Area Start

const FeatureBox=styled(Box)({
  background:'#F7F7F7',
  "& .MuiGrid-item":{
    padding: '24px',
    backgroundColor: '#fff',
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  "& .feature-list-container":{
    justifyContent:'space-between',
    padding: '30px 120px 40px 120px',
    alignItems:'center',
    border:'none',
    "@media(max-width: 992px)": {
      padding:'30px 33px 40px 33px',
        flexDirection:'column',
        img:{
          width:'50px',
        },
        h2:{
          fontSize:'14px',
        },
    },
  },
  "& .feature-list-left":{
    background:'transparent',
    maxWidth:'600px',
    border:'none !important',
    "& h2":{
      position:'relative',
      fontSize:'48px',
      "@media(max-width: 992px)": {
          fontSize:'14px',
      }
    },

    "& img":{
      zIndex:0,
      position:'absolute',
      "@media(max-width: 992px)": {
          width:'35px',
      }
    },
  },
  "& .feature-list-right":{
    background:'transparent',
    minWidth:'430px',
    border:'none !important',
    "@media(max-width: 992px)": {
      minWidth:'330px',
    },
    "& .MuiPaper-elevation1":{
      boxShadow:'none',
    },
    "& .MuiTypography-body1":{
      fontSize:'20px',
      color:'#000',
      fontWeight:'400',
  fontFamily: "Plus Jakarta Sans",
    },
    "& .MuiTypography-body2":{
      fontSize:'12px',
      fontFamily: "Plus Jakarta Sans",
      color:'#64748B',
}
  }
})





const ImageCardNew = styled(Card)({
  fontFamily: "Plus Jakarta Sans",
  boxShadow: 'none',
  height: "24.438rem",
  border: "none",
  "& .learn-more-box": {
    borderRadius: '8px',
    padding:'6px 21px',
    border: '1px solid #BB393D',
  },
  "@media (max-width:960px)": {
    width: "17.25rem",
  },
  "@media (min-width:720px)": {
    width: "21.563rem",
  },
  "& .MuiTypography-subtitle2": {

    textTransform: 'capitalize'
  }
});

const StatsFlow=styled(Box)({
  display:'flex',
  "& h5":{
    fontFamily: "Plus Jakarta Sans",
  },

  "& p":{

    fontFamily: "Plus Jakarta Sans",
  },
  "@media (max-width:768px)": {
    flexDirection:'column',
  },
});

const UnderImageBox = styled(Box)({
  background: "linear-gradient(180deg, rgba(187, 57, 61, 0) 0%, #BB393D 100%)",
  position: "relative",
  fontFamily: "Plus Jakarta Sans",
 
});

const GradiantColorBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  bottom: 0,
  left: 0,
  position: "absolute",
 
})

const AcheiveGoalButton = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  textTransform: 'capitalize',
  color: "white", fontWeight: "bold",
  
  "@media (max-width:960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem",
  }
})

const Banner = styled(Box)({
  alignItems: "center", minHeight: 645,
  fontFamily: "Plus Jakarta Sans",
  display: "flex", 
  justifyContent: "center",
  "@media (min-width:960px)": {
    height: 600,
  },
  "@media (min-width:600px)": {
    height: 300,
  },

});

const BannerUpDiv = styled(Box)({
  width: "100%",
  position: "absolute",
  opacity: 0.8,
  height: "640px",
  fontFamily: "Plus Jakarta Sans",
  borderRadius: "8px"
});

const ImageBoxTextHeader = styled(Typography)({
  fontSize: "24px",
  textAlign: 'left',
  fontFamily: "Plus Jakarta Sans",
  letterSpacing: 1,
  fontWeight: 'bold',
});

const ImageBoxTextDescription = styled(Typography)({
  fontSize: "14px",
  textAlign: "left",
  fontFamily: "Plus Jakarta Sans",
  letterSpacing: 1,
  fontWeight: "normal",
});

const BannerTxtDiv = styled(Box)({
  position: "relative",
  left: 0,
  fontFamily: "Plus Jakarta Sans",
  width: "100%",
  top: 0,
  height: "640px",
  background: "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #1C4A39 100%)",
});

const BannerHeader = styled(Typography)({
  color: "white",
  paddingLeft: "10vw",
   fontWeight: "bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize: "24px", width: "75vw",
    lineHeight: "28px",

  },
  "@media (min-width:720px)": {
    fontSize: "48px", width: "531px",
    lineHeight: "56px",
  }
})
const BannerSubHeader = styled(Typography)({
  color: "white",
  fontFamily: "Plus Jakarta Sans",
  paddingLeft: "10vw",
  marginBottom: '34px',
  "@media (max-width:960px)": {
    fontSize: "0.8rem", width: "75vw",
    lineHeight: "12px",
  },
  "@media (min-width:720px)": {
    fontSize: "1rem", width: "531px",
    lineHeight: "24px",
  }
})
const CenterTypography = styled(Typography)({
  width: "100%",
  fontFamily: "Plus Jakarta Sans",
  textAlign: "center",
  fontSize: "1.875rem",
  fontWeight: "bold",
  "@media (max-width:960px)": {
    fontSize: "1.4rem",
  },
  "@media (min-width:720px)": {
    fontSize: "1.875rem",
  }
});

const CenterTypographyTwo = styled(Typography)({
  width: "100%",
  textAlign: "center",
  lineHeight: "28px",
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "normal",
  margin: "auto",
  "@media (max-width:960px)": {
    fontSize: "1rem", width: "100%"
  },
  "@media (min-width:720px)": {
    fontSize: "1.25rem", width: "645px"
  }
});

const webStyles = {
  stepIndicatorBox: {
    flex: 1, margin: "30px 0px 0px 0px",
  },
  justifyEnd: {
    justifyContent: "flex-end", display: "flex"
  },
  justifyCenter: {
    justifyContent: "center",
    display: "flex"
  },
  stepOuterCircleBox: {
    display: "flex",
     flexDirection: "column",
    justifyContent: "center", 
    alignItems: "center",
    margin: "20px 0px 0px 0px",
  },
  fontBold: {
    fontWeight: "bold"
  },

  stepInnerCircleBox: {
    width: "54px", height: "54px",
      border: "1px solid #34D399",
     borderRadius: "50%",
    backgroundColor: "#34D399", 
    justifyContent: "center",
    alignItems: "center", display: "flex",
  },
  stepTypographyBox: {
    margin: "10px 0px 0px 0px", 
    alignContent: 'center',
    justifyContent: 'center'
  },
  getStartedBoardApplyMainBox: {
    flex: 1,
     margin: "5rem 0"
  },
  fieldsOfStudiesMainBox: {
    flex: 1,
    margin: "50px 0px 0px 50px",
  },
  widthAndHeight: {
    width: '280px', height: '80px'
  },
  buttonBox: {
    justifyContent: "center",
     alignItems: "center",
    display: "flex",
     padding: "2px",
    gap: 50,
     margin: "50px 0px 0px 50"
  },
  renderHomeHeroBox: {
    background: "white",
    padding: "2rem 0",
  },
  heroSectionBox: {
    fontSize: 38,
    fontWeight: "700",
    lineHeight: 1.3,
    letterSpacing: 1.5,
    marginBottom: "2rem"
  },
  headerDiv: {
    display: "flex",
    backgroundColor: "#F7F7F7"
  }

}
// Customizable Area End