// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    // Customizable Area End
}

// Customizable Area Start
interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface ValidResponseType {
    message: object;
    data: object;
}

interface InvalidResponseType {
    errors: Array<{ [key: string]: string }>
}
// Customizable Area End

interface S {
    // Customizable Area Start
    emailError: boolean;
    emailErrorTxt: string;
    emailValue: string;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    isSuccess: boolean;
    toggleDrawer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordEamilController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    forgotPasswordAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            emailError: false,
            emailErrorTxt: "",
            emailValue: "",
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            isSuccess: false,
            toggleDrawer:false
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId);
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson);
            }
        }
    };

    apiSucessCall = async (apiRequestCallId: string) => {
        if (apiRequestCallId === this.forgotPasswordAPICallId) {
            this.resetPswrdSucessCallBack();
        }
    };

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.forgotPasswordAPICallId) {
            this.resetPswrdFailureCallBack(responseJson);
        }
    };

    resetPswrdSucessCallBack = () => {
        this.setState({ isSuccess: true })
    }

    resetPswrdFailureCallBack = (responseJson: InvalidResponseType) => {
        const errorKey: string = Object.keys(responseJson.errors[0])[0];
        this.setState({
            isAlert: true,
            alertMsg: responseJson.errors[0][errorKey],
            alertType: "error"
        })
    }

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiCall = async (data: APIPayloadType) => {
        const {
            contentType,
            method,
            endPoint,
            body
        } = data;
        const header =
        {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), method
        );
        body
            &&
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    resetPswrdAPI = async () => {
        const body = {
            "data": {
                "attributes": {
                    "email": this.state.emailValue
                }
            }
        }
        this.forgotPasswordAPICallId = await this.apiCall({
            contentType: configJSON.forgotPasswordContentType,
            method: configJSON.forgotPasswordApimethod,
            endPoint: configJSON.forgotPasswordEndPoint,
            body: body,
        });
    };

    navigateToLoginPage = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleEmailChange = (event: { target: { value: string; }; }) => {
        const emailValue = event.target.value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
        if (emailValue === "") {
            this.setState({
                emailError: true,
                emailErrorTxt: "Email is required.",
                emailValue: emailValue
            });
        } else if (!emailRegex.test(emailValue)) {
            this.setState({
                emailError: true,
                emailErrorTxt: "Please enter a valid Email Address.",
                emailValue: emailValue
            });
        } else {
            this.setState({
                emailError: false,
                emailErrorTxt: "",
                emailValue: emailValue
            });
        }
    };

    onEmailClick = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
        if (this.state.emailValue.length === 0) {
            this.setState({ emailError: true, emailErrorTxt: "Email is required." })
        }
        else if (!emailRegex.test(this.state.emailValue)) {
            this.setState({ emailError: true, emailErrorTxt: "Please enter a valid Email Address." })
        }
        else {
            this.resetPswrdAPI()
        }
    };

    onHomeClick = (pageName:string) => {
        setStorageData("LandingPageActive",pageName)  
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };
    // Customizable Area End
}