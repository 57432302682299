// Customizable Area Start
import React from "react";
import { Table, Box, Typography, Button, TableSortLabel, TableCell, TableRow, TableHead, TableBody, Chip, Step, Stepper, TableContainer, Paper, StepLabel, StepConnector, withStyles, makeStyles, StepIconProps, Grid, MenuItem, Select, Dialog, IconButton, TextField, ListItemText, Checkbox, SvgIcon } from "@material-ui/core";
import { styled } from "@mui/material/styles";

import AllAplicationController, { Agent, ApplicationStageForInprogress, ApplicationStageForRefund, StudentDropDownData } from "./ApplicationsController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
export const configJSON = require("./config");
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardArrowDown } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Autocomplete } from "@mui/material";
import PaginationCompoent from "../../pagination/src/PaginationCompoent.web";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// Customizable Area End

export default class Applications extends AllAplicationController {
  // Customizable Area Start

  DiscountinueTableCell = (reason: string) => {
    return (
      <Box className="DiscountinuedContainer">
        <Box>
          <Typography variant="h6" className="ReasonText">
            Reason
          </Typography>
        </Box>
        <Box className="TextAndArrowContainer">
          <Box>
            <ArrowForwardIcon />
          </Box>
          <Box>
            <Typography variant="h6" className="AgentConfirmText">
              {reason}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  ApplicationClassNameCondition = (data: { application_status: string }) => {
    let ChipColorClass = "";
    let ChipText = "";
    if (data.application_status === "in_progress") {
      ChipColorClass = "InprogressClass";
      ChipText = "In Progress";
    }
    else if (data.application_status === "refund") {
      ChipColorClass = "RefundClass";
      ChipText = "refund";
    }
    else if (data.application_status === "enrolled") {
      ChipColorClass = "EnrolledClass";
      ChipText = "Enrolled";
    }
    else if (data.application_status === "discontinued") {
      ChipColorClass = "DiscountinuedClass";
      ChipText = "Discountinued";
    }
    return [ChipColorClass, ChipText];
  }

  PaginationCompoentRedner = () => {
    return this.state.AllData.length !== 0 &&
      <PaginationCompoent data-test-id="PagiNationCompoentId" sendData={this.handelPropsChange} data={this.state.AllData} itemsPerPage={this.state.itemsPerPage} navigation={undefined} />
  }

  TableHeadRender = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}
            style={{
              whiteSpace: "nowrap"
            }}
            className="tabHeaderCell positionStickyLeft">
            <Box style={{ display: "flex", gap: "10px" }}>
              <TableSortLabel
                active={true}
                direction={
                  this.state.orderBy === "RednerIndex" ? this.state.order : "asc"
                }
                data-test-id="RednerIndexSortTestId"
                onClick={() => this.handleRequestSort("RednerIndex")}
              >
                <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                  {configJSON.snoTxt}
                </Typography>
              </TableSortLabel>
              <TableSortLabel
                data-test-id="ApplicationIdSortTestId"
                active={true}
                direction={
                  this.state.orderBy === "application_id" ? this.state.order : "asc"
                }
                onClick={() => this.handleRequestSort("application_id")}
              >
                <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                  App ID
                </Typography>
              </TableSortLabel>
            </Box>
          </TableCell>
          <TableCell className="tabHeaderCell">
            <TableSortLabel
              data-test-id="CurrentStageSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("current_stage")}
              direction={this.state.orderBy === "current_stage" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" align="center" className="ApplicationsHeading">
                CURRENT STAGE
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell">
            <TableSortLabel
              data-test-id="StudentNameSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("student_name")}
              direction={this.state.orderBy === "student_name" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                STUDENT
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell">
            <TableSortLabel
              data-test-id="AgentBranchNameSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("AgentBranchName")}
              direction={this.state.orderBy === "AgentBranchName" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                Associated Branch
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell RemovePadding">
            <TableSortLabel
              data-test-id="CreateBySortTestId"
              active={true}
              onClick={() => this.handleRequestSort("create_by")}
              direction={this.state.orderBy === "create_by" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                CREATED BY
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell RemovePadding">
            <TableSortLabel
              data-test-id="UniversityNameSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("university_name")}
              direction={this.state.orderBy === "university_name" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                UNIVERSITY
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell RemovePadding">
            <TableSortLabel
              data-test-id="CourseSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("course")}
              direction={this.state.orderBy === "course" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                PROGRAM
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell RemovePadding">
            <TableSortLabel
              data-test-id="FeesSortTestId"
              active={true}
              onClick={() => this.handleRequestSort("fees")}
              direction={this.state.orderBy === "fees" ? this.state.order : "asc"}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                FEES
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell RemovePadding">
            <TableSortLabel
              data-test-id="requestSortCreateTestID"
              active={true}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                PAYMENT DATE
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell">
            <TableSortLabel
              data-test-id="StartDateSortTestId"
              active={true}
              direction={this.state.orderBy === "start_date" ? this.state.order : "asc"} onClick={() => this.handleRequestSort("start_date")}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                START DATE
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell">
            <TableSortLabel
              data-test-id="UpdatedAtSortTestId"
              active={true}
              direction={this.state.orderBy === "updated_at" ? this.state.order : "asc"} onClick={() => this.handleRequestSort("updated_at")}
            >
              <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                Date of Last Action
              </Typography>
            </TableSortLabel>
          </TableCell>
          <TableCell align="center" className="tabHeaderCell positionStickyRight">
            <Typography gutterBottom variant="h6" className="ApplicationsHeading">
              ACTIONS
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  StperCountAndSterperJsk = (data: { application_status: string, ApplicationStageForInprogress: ApplicationStageForInprogress, ApplicationStageForRefund: ApplicationStageForRefund, discontinued_reason: string }) => {
    let StperCount;
    let SterperJsk;
    if (data.application_status === "in_progress") {
      StperCount = this.SuccessStperCount(data.ApplicationStageForInprogress);
      SterperJsk = this.SuccessStepper(StperCount);
    } else if (data.application_status === "enrolled") {
      SterperJsk = this.SuccessStepper(Object.keys(data.ApplicationStageForInprogress).length);
    } else if (data.application_status === "refund") {
      StperCount = this.RefundStperCount(data.ApplicationStageForRefund);
      SterperJsk = this.RefundStepper(StperCount, data.ApplicationStageForRefund);
    } else if (data.application_status === "discontinued") {
      SterperJsk = this.DiscountinueTableCell(data.discontinued_reason);
    }

    return SterperJsk
  }


  ApplicationsDataRender = () => {
    const { ApplicationTableData, orderBy, order } = this.state;
    const sortedData = this.sortData(ApplicationTableData, orderBy, order);
    return (
      <ApplicationsStyled style={{ height: "100%", width: "100%", position: "relative" }} >
        <Box>
          <TableContainer className="TableContainer" data-test-id="TableContainer" component={Paper}
            onScroll={() => {
              this.handleScroll()
            }} ref={this.TableRef}>
            <Table aria-label="simple table" style={{
              position: "relative",
            }}>
              {this.TableHeadRender()}
              <TableBody>
                {
                  sortedData.length === 0 ?
                    <TableRow className="RowContainer">
                      <TableCell colSpan={8} className="tableInnerCell RemoveWHiteSpace">
                        <Typography gutterBottom variant="h6" align="center" className="SmallText">
                          No Data
                        </Typography>
                      </TableCell>
                    </TableRow> :
                    sortedData.map((data, index) => {
                      let SterperJsk = this.StperCountAndSterperJsk(data);
                      let ChipColorClass = this.ApplicationClassNameCondition({ application_status: data.application_status })[0];
                      let ChipText = this.ApplicationClassNameCondition({ application_status: data.application_status })[1];
                      return (
                        <>
                          <TableRow className="RowContainer" key={data.id}>
                            <TableCell colSpan={2} className="tableInnerCell RemoveWHiteSpace positionStickyLeft">
                              <div style={{ display: "flex", gap: "40px" }}>
                                <div>
                                  <Typography gutterBottom variant="h6" className="SmallText">
                                    {data.RednerIndex}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                    {data.application_id}
                                  </Typography>
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Chip label={ChipText} className={ChipColorClass} />
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="tableInnerCell RemoveWHiteSpace">
                              <Typography style={{ textTransform: "capitalize" }} gutterBottom variant="h6" className="SmallText">
                                {data.current_stage.replace(/_/g, " ")}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                              <Typography align="left" style={{ fontWeight: "700" }} gutterBottom variant="h6" className="SmallText BoldText">
                                {data.student_name}
                              </Typography>
                              <Typography align="left" gutterBottom variant="h6" className="SmallText">
                                <span className="LightGreyColorText">Student ID</span> - {data.generated_student_id}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableInnerCell">
                              <Typography gutterBottom variant="h6" align="center" className="SmallText">
                                {data.AgentBranchName}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.create_by}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.university_name}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.course}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.fees}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                N/A
                              </Typography>
                            </TableCell>
                            <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.start_date}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                {data.updated_at}
                              </Typography>
                            </TableCell>
                            <TableCell align="right"
                              className={"tableInnerCell positionStickyRight" + `${this.state.ExpendProgress === index ? " RemoveBorder " : ""}`}>
                              <Box className="ViewAppButtonContainer">
                                <Button className="ViewAppButton" data-test-id={"ViewApp" + index} onClick={() => {
                                  this.NaviGateToViewApplication(data.id, data.application_id)
                                }} variant="contained">
                                  View App
                                </Button>
                                <Button data-test-id={"ExpendBtn" + index} onClick={() => {
                                  this.setState({
                                    ExpendProgress: this.state.ExpendProgress === index ? -1 : index
                                  });
                                }} variant="outlined">
                                  {this.state.ExpendProgress === index ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
                                </Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                          {this.state.ExpendProgress === index &&
                            <TableRow className="RowContainer" style={{ backgroundColor: "#F8FAFC", position: "relative" }}>
                              {this.state.HideFirstCell &&
                                <TableCell className="positionStickyLeft" colSpan={2} />}
                              <TableCell colSpan={10}>
                                <Box>
                                  {SterperJsk}
                                </Box>
                              </TableCell>
                              <TableCell align="right" className="tableInnerCell positionStickyRight" />
                            </TableRow>}
                        </>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          {this.PaginationCompoentRedner()}
        </Box>
      </ApplicationsStyled>
    )
  }

  RefundStepper = (StepCount: number, data: {
    refund_initiated?: boolean,
    refund_received?: boolean,
    refund_rejected?: boolean,
    refund_submitted_to_institution?: boolean
  }) => {
    const { refund_received, refund_rejected } = data
    let RefundStperData = [...this.SecondStperData()]
    if (!refund_received && !refund_rejected) {
      RefundStperData.push("Refund Received")
    }
    if (refund_received) {
      RefundStperData.push("Refund Received")
    }
    if (refund_rejected) {
      RefundStperData.push("Refund Rejected")
    }
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={StepCount}
          className="StperClass RefundStperClass"
          connector={<RefundlibConnector />}
        >
          {RefundStperData.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={RefundColorlibStepIcon}>
                <Typography variant="h6" className="SteperHeading RefundSteperHeading">
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }

  SuccessStepper = (stpercount: number) => {
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={stpercount}
          className="StperClass"
          connector={<SuccessColorlibConnector />}
        >
          {this.StperData().map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                <Typography variant="h6" className={index === 0 ? "SteperHeading" : "SteperHeadingBlack"}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }

  TitleAndFilterAndTextFiledRender = () => {
    return (
      <Box className="TitltAndFilterContainer">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className="PageHeading">
              Applications
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item xs={12} md={4}>
                <SelectInput
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                  }}
                  name="AssociatedBranch"
                  onChange={this.HandleStatusChange}
                  value={this.state.StatusDropDownValue}
                  IconComponent={KeyboardArrowDown}
                  data-test-id="StudentAssociatedBranch1"
                  defaultValue="default"
                  className="SelectModelClass"
                >
                  <MenuItem value="" disabled>
                    <Typography style={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: "#64748B"
                    }} >Sort by status</Typography>
                  </MenuItem>
                  {this.state.StatusDropDown.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </SelectInput>
              </Grid>
              {
                this.state.StageDropDown.length > 0 &&
                <Grid item xs={12} md={4}>
                  <SelectInput
                    displayEmpty
                    MenuProps={{
                      getContentAnchorEl: null,
                      disableScrollLock: true,
                      anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                      },
                    }}
                    name="AssociatedBranch"
                    value={this.state.StageDropDownValue}
                    onChange={this.HandleStageChange}
                    IconComponent={KeyboardArrowDown}
                    data-test-id="HandleStageChange"
                    defaultValue="default"
                    className="SelectModelClass"
                  >
                    <MenuItem value="" disabled>
                      <Typography style={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: "#64748B"
                      }}>Sort by stage</Typography>
                    </MenuItem>
                    {this.state.StageDropDown.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}
                  </SelectInput>
                </Grid>
              }
              <Grid item xs={12} md={4}>
                <Button data-test-id="FilterButton" onClick={this.FilterOpen} startIcon={<FilterListIcon />} fullWidth variant="contained" className="FilterButton">
                  Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  FilterModelRender = () => {
    const { FilterModelOpen, StudentFilterDropDownData,
      CreatedByFilterDropDownData,
      UniversityFilterDropDownData,
      ProgramFilterDropDownData,
      AssociateBranchNameFilterDropDownData, } = this.state
    return (
      <FilterModalStyle
        open={FilterModelOpen}
        maxWidth="sm"
        scroll="paper"
      >
        <Box className="BoxContainer">
          <Box className="CloseIconContainer">
            <IconButton data-test-id="CloseUploadModel" onClick={this.FilterOpen}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom align="center" variant="h6" className="ModalHeadig FilterTitleContainer">
            <FilterListIcon />
            Filter
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={10} md={8}>
              <LabelsTypography>
                Student
              </LabelsTypography>
              <AutoComplateStyle
                data-test-id="StudentDropDown"
                className="selectInput autocompleteField"
                multiple
                options={StudentFilterDropDownData}
                getOptionLabel={(option: unknown) => {
                  return (option as { student_id: string }).student_id
                }}
                value={this.state.StudentDropDownValue}
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                  this.StudentDropDownDataChange(newValue as Array<StudentDropDownData>)
                }}
                renderOption={(props, option: unknown, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={`${option}`} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={(option as { student_name: string }).student_name}
                      />
                    </Box>
                  );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    className="inputField"
                    placeholder="Select student (s)"
                    {...params}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
              />
              <Box>
                {this.state.StudentDropDownValue.map((option) => (
                  <StyledChip
                    data-test-id="StudentDataChip"
                    key={option.student_id}
                    label={option.student_name}
                    onDelete={() => { this.DelteStundeDropDown(option.student_id) }}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={10} md={8}>
              <LabelsTypography>
                Associated branch
              </LabelsTypography>
              <AutoComplateStyle
                data-test-id="AssociatedBranchDropDown"
                className="selectInput autocompleteField"
                multiple
                options={AssociateBranchNameFilterDropDownData}
                getOptionLabel={(option: unknown) => {
                  return option as string
                }}
                value={this.state.AssociateBranchValue}
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                  this.AssociatedBranchDataChange(newValue as Array<string>)
                }}
                renderOption={(props, option: unknown, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={`${option}`} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText primary={option as string} />
                    </Box>
                  );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    className="inputField"
                    placeholder="Select branch"
                    {...params}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
              />
              <Box>
                {this.state.AssociateBranchValue.map((option) => (
                  <StyledChip
                    data-test-id="AssociateBranchValueChip"
                    key={option}
                    label={option}
                    onDelete={() => { this.DelteAssociatedBranch(option) }}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={10} md={8}>
              <LabelsTypography>
                Created by
              </LabelsTypography>
              <AutoComplateStyle
                data-test-id="CreatedByDropDown"
                className="selectInput autocompleteField"
                multiple
                options={CreatedByFilterDropDownData}
                getOptionLabel={(option: unknown) => (option as { agent_name: string }).agent_name}
                value={this.state.CreatedByDropDownValue}
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                  this.CreatedByDropDownDataChange(newValue as Array<Agent>)
                }}
                renderOption={(props, option: unknown, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={`${(option as { agent_id: string }).agent_id}`} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={(option as { agent_name: string }).agent_name}
                        secondary={
                          <>
                            <strong>Agent ID:</strong> {`${(option as { agent_id: string }).agent_id}`}
                          </>
                        }
                      />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder={configJSON.selectCreatedByPlaceholder}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={() => null}
              />
              <Box>
                {this.state.CreatedByDropDownValue.map((option) => (
                  <StyledChip
                    data-test-id="CreatedByDropDownChip"
                    key={option.agent_id}
                    label={option.agent_name}
                    onDelete={() => { this.DelteCreatedBy(option.agent_id) }}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={10} md={8}>
              <LabelsTypography>
                University
              </LabelsTypography>
              <AutoComplateStyle
                data-test-id="UniversityDropDown"
                className="selectInput autocompleteField"
                multiple
                options={UniversityFilterDropDownData}
                getOptionLabel={(option: unknown) => {
                  return option as string
                }}
                value={this.state.UniversityByDropDownValue}
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                  this.UniversityDropDownDataChange(newValue as Array<string>)
                }}
                renderOption={(props, option: unknown, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={`${option}`} {...otherProps}>
                      <StyledCheckBox
                        checked={selected}
                        style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={option as string}
                      />
                    </Box>
                  );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select university"
                    variant="standard"
                    className="inputField"
                    {...params}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
              />
              <Box>
                {this.state.UniversityByDropDownValue.map((option) => (
                  <StyledChip
                    data-test-id="UniversityDropDownChip"
                    key={option}
                    label={option}
                    onDelete={() => { this.DelteUniversity(option) }}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={10} md={8}>
              <LabelsTypography>
                Program
              </LabelsTypography>
              <AutoComplateStyle
                data-test-id="ProgramDropDown"
                className="selectInput autocompleteField"
                multiple
                options={ProgramFilterDropDownData}
                getOptionLabel={(option: unknown) => {
                  return option as string
                }}
                value={this.state.ProgramDropDownValue}
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                  this.ProgramDropDownDataChange(newValue as Array<string>)
                }}
                renderOption={(props, option: unknown, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li"
                      key={`${option}`}
                      {...otherProps}>
                      <StyledCheckBox
                        checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText
                        primary={option as string}
                      />
                    </Box>
                  );
                }}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    className="inputField"
                    {...params}
                    placeholder="Select program"
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
              />
              <Box>
                {this.state.ProgramDropDownValue.map((option) => (
                  <StyledChip
                    data-test-id="ProgramDropDownChip"
                    key={option}
                    label={option}
                    onDelete={() => { this.DelteProgram(option) }}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} style={{ color: 'red' }} />}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={10} md={8}>
              <Grid container spacing={4} className="FilterButtonContainer">
                <Grid item xs={12} md={6}>
                  <Button data-test-id="ResetFilterBtn" fullWidth onClick={this.ResetData} variant="contained" className="ResetFilterBtn">
                    Reset Filter
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button data-test-id="ApplyFilterBtn" onClick={this.FilterModelApiCall} fullWidth variant="contained" className="ApplyFilterBtn">
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </FilterModalStyle>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ApplicationsStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
            showScool={""}
          />
          
          <Box className="contentBox">
            <SideMenuBar data-test-id="sidebarNavigationBtn"
              navigationToAnyPage={this.navigationToAnyPage} activeComponent="Applications" />

            <Box className="mainBar">
              {this.TitleAndFilterAndTextFiledRender()}
              {this.ApplicationsDataRender()}
              {this.FilterModelRender()}
            </Box>
          </Box>
        </LeadBox>
      </ApplicationsStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const FilterModalStyle = styled(Dialog)({
  position: "relative",
  padding: "20px",
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px"
  },
  "& .HeadingContainer": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiDialogContent-root": {
    padding: "0px 40px",
    minWidth: "400px"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: "capitalize",
    marginBottom: "20px",
    textAlign: "center",
  },
  "& .ModalSubHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: "30px"
  },
  "& .BoxContainer": {
    overflowX: "hidden",
    width: "480px",
    paddingBottom: "20px",
    "@media only screen and (max-width: 425px) ": {
      width: "unset",
    },
    "& .ResetFilterBtn": {
      color: "#34D399",
      whiteSpace: "noWrap",
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "#D6F6EB",
      boxShadow: "none",
    },
    "& .ApplyFilterBtn": {
      border: '1px solid #34D399',
      textTransform: 'capitalize',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: '700',
      textAlign: 'center',
      padding: "5px 8px",
      borderRadius: "6px",
      color: 'white',
      boxShadow: "none",
      backgroundColor: "#34D399"
    },
    "& .FilterTitleContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px"
    },
    "& .selectField": {
      height: "50px",
      "& .MuiSelect-select.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)"
      },
      "& :hover": {
        borderBottom: "0px solid red"
      }
    },
    "& .autocompleteField": {
      "& input": {
        marginTop: "10px"
      }
    },
  },
  "& .selectInput": {
    width: "100%",
    maxWidth: "350px",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    height: "50px",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .filterTitle": {
    color: "var(--Basic-Black, #000)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px"
  },
  "& .insideBox": {
    padding: "16px 30px",
    "& .innerContent": {
      paddingTop: "16px"
    }
  },
  "& .inputTxt": {
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#334155"
  },
  "& .autocompleteField": {
    "& input": {
      marginTop: "10px"
    }
  },
})

const ApplicationsStyle = styled(Box)({
  "& .TitltAndFilterContainer": {
    width: "100%",
    margin: "20px 0px",
    "& .SelectModelClass": {
      maxHeight: "40px",
      minHeight: "40px"
    },
    "& .PageHeading": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "22px !important",
      fontWeight: '700 !important'
    },
    "& .FilterButton": {
      backgroundColor: "#373335",
      color: 'white',
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 700,
      textTransform: "capitalize",
      borderRadius: "6px",
    }
  },
  "& .CardContainer": {
    "@media only screen and (max-width: 425px) ": {
      padding: "0 5px"
    },
  },
})

const LeadBox = styled(Box)(({ theme }) => ({
  "& .contentBox": {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridAutoFlow: "column",
      gridAutoColumns: "minmax(1px, auto)",
    },
  },
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    [theme.breakpoints.down("md")]: {
      marginLeft: "64px",
    },
    padding: "28px 50px 28px 100px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
  },
}));

const ApplicationsStyled = styled(Box)({
  "& .TableContainer": {
    overflowY: "hidden",
    position: "relative",
    "&::-webkit-scrollbar": {
      marginTop: "10px",
      height: '10px',
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: 'none',
      borderRadius: '2px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#CBD5E1',
      borderRadius: '2px',
    },
  },
  "& .RemovePadding": {
    // padding: "0"
  },
  "& .RemoveBorder": {
    border: "none"
  },
  "& .positionStickyLeft": {
    position: "sticky",
    left: "-1px",
    zIndex: 10,
    backgroundColor: "#fff",
    boxShadow: '-2px 0px 8px 0px #00000014',
    "@media (max-width: 425px)": {
      position: "unset",
    },
  },
  "& .MuiTableSortLabel-icon": {
    color: "#94A3B8B2 !important"
  },
  "& .RemoveWHiteSpace": {
    whiteSpace: "nowrap"
  },
  "& .positionStickyLeft::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
  },
  "& .positionStickyRight": {
    position: "sticky",
    right: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    boxShadow: "-5px 0 10px rgba(0,0,0,0.1)",
    "@media (max-width: 425px)": {
      position: "unset",
    },
  },
  "& .positionStickyRight::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
  },
  "& .ApplicationsHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: "capitalize",
    color: "#0F172A"
  },
  "& .ApplicationsSubHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: "capitalize",
  },
  "& .NoDataAvailable": {
    textAlign: "center"
  },
  "& .RowContainer": {
    position: "relative",
    "& .SmallText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: '400',
      color: "#212121"
    },
    "& .BoldText": {
      fontWight: "900"
    },
    "& .LightGreyColorText": {
      color: "#64748B"
    },
    "& .InprogressClass": {
      backgroundColor: "#FEF3C7",
      color: "#D97706"
    },
    "& .ViewAppButtonContainer": {
      display: "flex",
      gap: "5px"
    },
    "& .ViewAppButton": {
      color: "#34D399",
      whiteSpace: "noWrap",
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "white",
      boxShadow: "none",
      marginRight: "5px",
    },
    "& .PipelineContainer": {
      backgroundColor: "#F8FAFC",
      "& .StperClass": {
        padding: "0px !important",
        backgroundColor: "transparent",
      },
      "& .RefundStperClass": {
        width: "fit-content"
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "4px"
      },
      "& .SteperHeading": {
        color: "#34D399",
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'center',
      },
      "& .MuiStepConnector-alternativeLabel": {
        left: "calc(-50% + 0px) !important",
        right: "calc(50% + 0px) !important"
      },
      "& .SteperHeadingBlack": {
        color: "#64748B",
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'center',
      },
      "& .RefundSteperHeading": {
        color: "#0042EA",
      }
    }
  },
  "& .DiscountinuedContainer": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "& .ReasonText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: '400',
      color: "#64748B"
    },
    "& .AgentConfirmText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: '400',
      color: "#000000"
    },
    "& .TextAndArrowContainer": {
      display: "flex",
      alignItems: "center",
      gap: "10px"
    }
  },
  "& .InprogressClass": {
    backgroundColor: "#FEF3C7", color: "#D97706",
    textTransform: "uppercase",
  },
  "& .RefundClass": {
    backgroundColor: "#F0F8FF", color: "#0042EA",
    textTransform: "uppercase",
  },
  "& .DiscountinuedClass": {
    backgroundColor: "#F1F5F9", color: "#475569",
    textTransform: "uppercase",
  },
  "& .EnrolledClass": {
    backgroundColor: "#D1FAE5", color: "#059669",
    textTransform: "uppercase",
  },
})

const SuccessColorlibConnector = withStyles({
  alternativeLabel: { top: 10, },
  active: {
    '& $line': { backgroundColor: '#34D399', },
  },
  completed: {
    '& $line': { backgroundColor: '#34D399', },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

function SuccessColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const {
    active,
    completed
  } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}



const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #34D399",
  },
  active: {
    backgroundColor: "#34D399"
  },
  completed: {
    backgroundColor: "#34D399"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});

const SelectInput = styled(Select)({
  justifyContent: "center",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background: "white",
  width: "100%",
  height: "100%",
  display: "flex",
  margin: "5px 0px",
  boxSizing: "border-box",
  padding: "0px 10px",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": { outline: "none" },
    "&::before": { content: "none" },
    "&::after": { content: "none" }
  },
  "&.MuiInput-underline:before": { borderBottom: "0px solid red !important" },
  "&.MuiInput-underline:after": { borderBottom: "0px solid red !important" },
  "&:hover:not(.Mui-disabled):before": { borderBottom: "0px solid red !important", },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red !important"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiInputBase-input": {
    color: '#64748B',
    fontWeight: 700,
  },

});

const LabelsTypography = styled(Typography)({
  "&.MuiTypography-root": {
    fontWeight: '700',
  },
  fontSize: "16px",
  fontFamily: "Plus Jakarta Sans",
})

function RefundColorlibStepIcon(props: StepIconProps) {
  const classesNew = useRefundColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classesNew.root, {
        [classesNew.active]: active,
        [classesNew.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}

const useRefundColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff", zIndex: 1, color: "#fff",
    width: 24, height: 24, display: "flex",
    borderRadius: "50%", justifyContent: "center", alignItems: "center",
    border: "1px solid #0042EA",
  },
  active: {
    backgroundColor: "#0042EA"
  },
  completed: {
    backgroundColor: "#0042EA"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});

const RefundlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#0042EA',
    },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

const StyledCheckBox = styled(Checkbox)({
  borderRadius: "6px",
  border: "1px solid var(--Neutrals-Cool-gray-400, rgba(148, 163, 184, 0.70))",
  width: "20px",
  height: "20px",
  "@media (min-width: 600px)": {
    width: "18px",
    height: "18px"
  },
  "@media (min-width: 960px)": {
    width: "16px",
    height: "16px"
  }
});

const AutoComplateStyle = styled(Autocomplete)({

});

const StyledChip = styled(Chip)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "4px",
  backgroundColor: "white !important",
  "& .MuiChip-label": {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: "100px",
    "@media (min-width: 600px)": {
      fontSize: "13px",
      lineHeight: "20px"
    },
    "@media (min-width: 960px)": {
      fontSize: "12px",
      lineHeight: "18px"
    }
  }
});

// Customizable Area End